<template>
  <div class="cr-partition-title">
    <v-btn icon small @click="$emit('update:showList', !showList)">
      <v-icon>
        {{ showList ? "mdi-chevron-down" : "mdi-chevron-right" }}
      </v-icon>
    </v-btn>
    {{ title }}
  </div>
</template>

<style lang="scss" scoped>
.cr-partition-title {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  // cursor: pointer;
}
</style>

<script>
export default {
  props: {
    showList: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    title() {
      switch (this.$vnode.key) {
        case "PENDING":
          return "참여 대기 중인 프로젝트";
        case "INVITED":
          return "초대받은 프로젝트";

        default:
          return "참여 중인 프로젝트";
      }
    }
  }
};
</script>
