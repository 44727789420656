<template>
  <v-btn
    icon
    x-small
    outlined
    class="ml-2 primary--text text--darken-3"
    @click="manageMember"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"])
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapMutations("flowProjects", ["SET_MANAGED_PROJECT_ID"]),
    manageMember() {
      this.SET_MANAGED_PROJECT_ID(this.selectedProject.id);
      this.SHOW_DIALOG({
        headline: "프로젝트 멤버 관리",
        type: "manageMember"
      });
    }
  }
};
</script>
