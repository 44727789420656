<template>
  <div class="cr-flow-assignee-menu-wrapper">
    <SearchField :searchWord.sync="searchWord" />
    <MemberList :searchWord="searchWord" v-bind="$props" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-assignee-menu-wrapper {
  padding: 8px 12px;
  background-color: #fff;
}
</style>

<script>
import SearchField from "./searchField";
import MemberList from "./list";

export default {
  components: { SearchField, MemberList },
  props: {
    assignees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { searchWord: "" };
  }
};
</script>
