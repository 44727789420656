<template>
  <div class="cr-status-and-priority-wrapper">
    <Status />
    <div class="cr-divider" />
    <Priority />
  </div>
</template>

<style lang="scss" scoped>
.cr-status-and-priority-wrapper::v-deep {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  border-radius: 8px;
  background-color: #fff;
  border: thin solid rgba(0, 0, 0, 0.08);

  .cr-divider {
    width: 2px;
    height: 70%;
    background: rgba(0, 0, 0, 0.06);
  }
}
</style>

<script>
import Status from "./status";
import Priority from "./priority";

export default {
  components: { Status, Priority }
};
</script>
