<template>
  <div class="cr-flow-loader-wrapper">
    <Group :group="group" />
    <TaskMockup v-for="i in 14" :key="`${group.id}_${i}`" />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-loader-wrapper {
  position: absolute;
  top: 105px;
  left: 16px;
  right: 16px;
  bottom: 0px;
  z-index: 1;
  background-color: #fff;
}
</style>

<script>
import Group from "../groupContainer/group";
import TaskMockup from "../groupContainer/mockupContainer/TaskMockup.vue";

export default {
  components: { Group, TaskMockup },
  props: {
    group: {
      type: Object,
      default: () => ({
        text: "미지정",
        isDefault: 1
      })
    }
  }
};
</script>
