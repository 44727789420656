<template>
  <div
    class="cr-flow-main fill-height"
    :class="{ 'cr-tasks': isRender('tasks') }"
  >
    <Dashboard v-if="isRender()" />
    <Tasks v-if="isRender('tasks')" />
    <Projects v-if="isRender('projects')" />

    <Dialog />
    <portal-target name="flowMenu" />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-main {
  padding: 16px 20px;
  &.cr-tasks {
    padding: 0px;
  }
}
</style>

<script>
import { mapMutations } from "vuex";
import Dialog from "@/flow/views/components/dialog";
import Dashboard from "@/flow/views/components/dashboard";
import Tasks from "@/flow/views/components/tasks";
import Projects from "@/flow/views/components/projects";

export default {
  components: { Dialog, Dashboard, Tasks, Projects },
  mounted() {
    HTMLCollection.prototype.indexOf = Array.prototype.indexOf;
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
    HTMLCollection.prototype.at = Array.prototype.at;
  },
  methods: {
    ...mapMutations("splash", ["SET_SHOW_SPLASH"]),
    isRender(view) {
      const { name } = this.$route;
      return name.split("_")[1] == view;
    },
    beforeRoute() {
      // to, from
      // const { name: toName, params: toParams } = to;
      // const { name: fromName, params: fromParams } = from;

      this.SET_SHOW_SPLASH(false);
    }
  },

  /**
   * 처음 들어올때 setParams -> Enter,
   * 라우트 이동시 Leave -> setParams -> Enter
   * 같은 라우트 이동시 setParams -> Update
   *
   * 같은 라우트 이동시 파라미터까지 동일하면 라우트 중복에러 발생
   * 위와 같은 경우 초기화는 route.js 에서 처리
   *
   */
  beforeRouteEnter(to, from, next) {
    next(vm => vm.beforeRoute(to, from));
  },
  beforeRouteUpdate(to, from, next) {
    this.beforeRoute(to, from);
    next();
  }
};
</script>
