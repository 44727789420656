<template>
  <v-checkbox dense hide-details v-model="checked" />
</template>

<style lang="scss" scoped>
.v-input.v-input--checkbox {
  margin-top: 0px;
}
</style>

<script>
export default {
  emits: ["update:checklist"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    checked: {
      get() {
        return this.item?.checked || false;
      },
      set(checked) {
        checked = checked ? 1 : 0;
        this.$emit("update:checklist", { ...this.item, checked });
      }
    }
  }
};
</script>
