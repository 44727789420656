<template>
  <v-checkbox
    v-if="!isSubTask"
    dense
    hide-details
    hide-spin-buttons
    color="grey"
    class="cr-list-checkbox"
    :disabled="!hasEditPermission"
    v-model="checked"
    @click.stop
  />
  <div v-else style="width: 32px;"></div>
</template>

<style lang="scss" scoped>
.v-input.v-input--checkbox {
  margin-top: 0px;
  padding-top: 2px;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", ["checkedTasks"]),
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    checked: {
      get() {
        const index = this.checkedTasks.findIndex(t => t.id === this.task.id);
        return index !== -1;
      },
      set() {
        this.CHECK_TASK(this.task);
      }
    },
    isSubTask() {
      return this.task.parentId > 0;
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["CHECK_TASK"])
  }
};
</script>
