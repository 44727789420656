<template>
  <div class="cr-reapply-wrapper caption">
    <span class="cr-reapply mr-2" @click="reapply">신청 알림 다시 보내기</span>
    <span>{{ `마지막 발송시간 : ${joinRequestTime}` }}</span>
  </div>
</template>

<style lang="scss" scoped>
.cr-reapply-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .cr-reapply {
    cursor: pointer;
    color: var(--v-primary-base);
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { getDateWithTimeSecond } from "@/commons/utils/moment";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    ...mapGetters("auth", ["getUserInfo"]),
    joinRequestTimeMillis() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject;
      return members?.find(m => m.userId == id)?.joinRequestTimeMillis || 0;
    },
    joinRequestTime() {
      return getDateWithTimeSecond(this.joinRequestTimeMillis);
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("flowProjects", ["resendApplication"]),
    reapply() {
      // 24시간 이후에 재발송가능
      if (this.joinRequestTimeMillis) {
        const f_time = new Date(this.joinRequestTimeMillis + 86400000);
        if (f_time.getTime() > new Date().getTime()) {
          const message = "24시간 이후 보낼 수 있습니다.";
          return this.openSnackbar({ message });
        }

        this.resendApplication(this.selectedProject.id);
      }
    }
  }
};
</script>
