<template>
  <div style="display: contents;">
    <v-text-field
      v-if="editable"
      ref="textRef"
      dense
      hide-details
      outlined
      :value="item.title"
      @blur="esc"
      @keydown.esc.stop="esc"
      @keydown.enter="enter"
    />

    <div v-else class="cr-checkitem" @click="changeEditable">
      {{ item.title }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-checkitem {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 11px;
  border: thin solid transparent;
  &:hover {
    border-radius: 8px;
    background: #fff;
    border-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }
}

.v-text-field::v-deep {
  background: #fff;
  border-radius: 8px !important;
  fieldset {
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
  input {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>

<script>
import { isBlank } from "@/commons/utils/validation";

export default {
  emits: ["update:checklist"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeEditable() {
      this.$emit("update:editable", true);
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    esc() {
      this.$emit("update:editable", false);
      this.$refs.textRef.reset();
    },
    enter(e) {
      const title = e.target.value.trim();
      if (isBlank(title)) return;

      this.$emit("update:checklist", { ...this.item, title });
      this.esc();
    }
  }
};
</script>
