<template>
  <div class="cr-task-check-list">
    <Progress v-bind="$props" />
    <List v-bind="$props" v-on="$listeners" />
    <AddInput v-if="!disabled" v-bind="$props" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-check-list {
  padding: 16px 12px 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.03);
  border: thin solid rgba(0, 0, 0, 0.04);
}
</style>

<script>
import Progress from "./progress";
import List from "./list";
import AddInput from "./addInput";

export default {
  components: { Progress, List, AddInput },
  emits: ["add:checklist", "update:checklist", "delete:checklist"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
