<template>
  <div class="cr-group-container-wrapper">
    <Group :group="group" :collapsed="collapsed" />
    <div v-if="!collapsed" class="dnd-tasks" style="display: contents;">
      <Task v-for="task in tasks" :key="task.id" :group="group" :task="task" />
      <MockupContainer :key="tasks.length" :group="group" />
      <BottomSection :group="group" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-group-container-wrapper {
  width: max-content;
  min-width: 100%;

  .cr-bottom-section {
    min-height: 60px;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import Group from "./group";
import Task from "./task";
import MockupContainer from "./mockupContainer";
import BottomSection from "./bottomSection";

export default {
  components: { Group, Task, MockupContainer, BottomSection },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", ["collapsedGroupList"]),
    ...mapGetters("flowTasks", ["LIST_tasks"]),
    tasks() {
      return this.LIST_tasks[this.group.id] || [];
    },
    collapsed() {
      return this.collapsedGroupList.includes(this.group.id);
    }
  }
};
</script>
