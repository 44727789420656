<template>
  <div class="fill-height">
    <Toolbar />
    <!-- 상세화면 -->
    <Detail :key="detailTaskId" />
    <!-- 목록, 칸반, 간트 -->
    <component :is="$props.components[viewType]" />
    <!-- 셀렉트 했을때 메뉴 -->
    <ActionMenu />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Toolbar from "./toolbar";
import Detail from "./detail";
import ActionMenu from "./actionMenu";
import LIST from "./list";
import KANBAN from "./kanban";
import GANTT from "./gantt";

export default {
  components: { Toolbar, Detail, ActionMenu },
  props: {
    components: {
      type: Object,
      default: () => ({ LIST, KANBAN, GANTT })
    }
  },
  async created() {
    const { projectId } = this;
    if (!parseInt(projectId) || this.isFetch) return;

    await this.initTasks(projectId);
  },
  data() {
    return { isFetch: false };
  },
  computed: {
    ...mapState("flowRoute", ["projectId"]),
    ...mapState("flowTasks", ["detailTaskId"]),
    ...mapGetters("flowRoute", ["viewType", "searchWord", "filterId"])
  },
  watch: {
    async projectId(projectId) {
      if (!parseInt(projectId) || this.isFetch) return;

      await this.initTasks(projectId);
    },
    viewType() {
      const { projectId } = this;
      if (!parseInt(projectId) || this.isFetch) return;

      this.fetchTasks();
    },
    searchWord() {
      const { projectId } = this;
      if (!parseInt(projectId) || this.isFetch) return;

      this.fetchTasks();
    },
    filterId(filterId) {
      const { projectId } = this;
      if (!filterId || !parseInt(projectId) || this.isFetch) return;

      this.fetchTasks();
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["RESET_TASKS"]),
    ...mapMutations("flowProjects", ["SET_SELECTED_PROJECT_ID"]),
    ...mapActions("flowProjects", ["getProjectDetail"]),
    ...mapActions("flowTasks", ["getInitialData"]),
    async initTasks(projectId) {
      this.isFetch = true;
      this.RESET_TASKS();
      this.SET_SELECTED_PROJECT_ID(null);

      await this.SET_SELECTED_PROJECT_ID(projectId);
      await this.getProjectDetail(projectId);
      await this.fetchTasks();
      this.isFetch = false;
    },
    async fetchTasks() {
      this.RESET_TASKS();
      await this.getInitialData();
    }
  }
};
</script>
