<template>
  <DetailDialog>
    <div class="cr-task-detail-wrapper">
      <Header />
      <Content />
    </div>
  </DetailDialog>
</template>

<style lang="scss" scoped>
.cr-task-detail-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}
</style>

<script>
import { mapGetters } from "vuex";
import DetailDialog from "./DetailDialog.vue";
import Header from "./header";
import Content from "./content";

export default {
  components: { DetailDialog, Header, Content },
  computed: {
    ...mapGetters("flowTasks", { enabled: "hasEditPermission" })
  }
};
</script>
