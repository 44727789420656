<template>
  <div class="cr-project-detail cr-members">
    <div class="d-flex align-center">
      <v-btn icon @click="showValue = !showValue">
        <v-icon>
          {{ showValue ? "mdi-chevron-down" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>

      <h4>프로젝트 멤버</h4>
      <manageMemberBtn v-if="isManager" />
    </div>

    <div v-if="showValue" class="cr-text py-2 pb-1">
      <Members v-for="authority in authorities" :key="authority" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-detail.cr-members {
  .cr-text {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ManageMemberBtn from "./ManageMemberBtn.vue";
import Members from "./Members.vue";

export default {
  components: { ManageMemberBtn, Members },
  data() {
    return {
      showValue: true,
      authorities: ["MASTER", "ADMIN", "EDIT", "READ", "PENDING"]
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("flowProjects", ["selectedProject"]),
    isManager() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject;
      const member = members?.find(m => m.userId == id);

      return (
        member &&
        member?.joinStatus === "ACCEPTED" &&
        (member?.authority === "MASTER" || member?.authority === "ADMIN")
      );
    }
  }
};
</script>
