<template>
  <div class="cr-task-mockup">
    <v-skeleton-loader class="d-flex" type="text" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-mockup::v-deep {
  height: 42px;
  margin: 4px 0px;
  .v-skeleton-loader {
    border-radius: 0px;
    .v-skeleton-loader__text {
      width: 24px;
      height: 42px;
      border-radius: 0px;
    }
  }
}
</style>

<script>
export default {};
</script>
