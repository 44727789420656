<template>
  <div class="cr-left-panel-wrapper">
    <Content />
    <CheckList class="mt-3" />
    <SubTasks class="my-3" />
  </div>
</template>

<style lang="scss" scoped>
.cr-left-panel-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 16px;
}
</style>

<script>
import Content from "../commons/content";
import CheckList from "../commons/checklist";
import SubTasks from "../commons/subTasks";

export default {
  components: { Content, CheckList, SubTasks }
};
</script>
