<template>
  <v-textarea
    rows="4"
    ref="textRef"
    outlined
    no-resize
    hide-details
    hide-spin-buttons
    :value="content"
    :loading="loading"
    :disabled="loading"
    placeholder="업무에 필요한 정보를 작성해주세요."
    @blur="blur"
    @keyup.stop="keyup"
    @keydown.stop="keydown"
  />
</template>

<style lang="scss" scoped>
.v-textarea::v-deep {
  height: 138px !important;
  min-height: 138px !important;
  width: 100%;
  fieldset {
    right: -1px;
    border-radius: 8px;
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
  textarea {
    color: #333;
    font-size: 16px;
    margin: 14px 0px 10px !important;
  }
}
</style>

<script>
export default {
  emits: ["enter"],
  props: {
    mode: {
      type: String,
      default: "view"
    },
    task: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.mode === "add") return;
      this.$refs.textRef.focus();
    }, 0);
  },
  data() {
    return { pressedShift: false };
  },
  computed: {
    content() {
      return this.task?.content || "";
    }
  },
  methods: {
    focus() {
      this.$refs.textRef.focus();
    },
    blur() {
      if (this.loading) return;
      this.$emit("changeEditable", false);
    },
    toggleEditable() {
      this.$emit("changeEditable", false);
    },
    keyup(e) {
      if (e.keyCode === 16) {
        this.pressedShift = false;
      }
    },
    keydown(e) {
      switch (e.keyCode) {
        // ESC
        case 27:
          return this.blur();
        // Shift
        case 16:
          this.pressedShift = true;
          break;
        // Enter
        case 13:
          if (this.pressedShift) return;

          e.preventDefault();
          this.$emit("enter", { e, toggleEditable: this.toggleEditable });
          break;
        default:
          break;
      }
    }
  }
};
</script>
