<template>
  <RangeDate
    :task="task"
    @change:rangeDate="
      rangeDate => $emit('change:rangeDate', { task, rangeDate })
    "
  />
</template>

<script>
import RangeDate from "@/flow/views/components/commons/task/rangeDate";

export default {
  components: { RangeDate },
  emits: ["change:rangeDate"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
