<template>
  <v-tooltip top>
    <template v-slot:activator="{ attrs, on }">
      <v-icon size="20" class="mr-1" v-on="on" v-bind="attrs">
        {{ icon }}
      </v-icon>
    </template>

    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    icon() {
      const { privateStatus } = this.project;
      return privateStatus ? "mdi-lock" : "mdi-earth";
    },
    tooltip() {
      const { privateStatus } = this.project;
      return privateStatus ? "비공개" : "전체공개";
    }
  }
};
</script>
