<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on" :disabled="disabled" @click="toggle">
        <v-icon>mdi-swap-horizontal</v-icon>
      </v-btn>
    </template>

    <span>내 프로젝트로 돌아가기</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    timeout: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState("flowProjects", ["loading"]),
    disabled() {
      return this.loading || !!this.timeout;
    }
  },
  methods: {
    ...mapActions("flowProjects", { toggle: "toggleShowMyProjects" })
  }
};
</script>
