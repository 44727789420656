<template>
  <div>
    <TaskMockup v-for="i in mockupCnt" :key="`${status.value}_${i}`" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TaskMockup from "./TaskMockup.vue";

export default {
  components: { TaskMockup },
  props: {
    status: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (!this.mockupCnt) return;

    new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (!entry.isIntersecting) continue;
        if (!this.mockupCnt) return;

        const { value: status } = this.status;
        const lastTask = (this.KANBAN_tasks[status] || []).at(-1);
        this.getTasks({ sortOrder: lastTask?.kanbanSortOrder, status });
      }
    }).observe(this.$el);
  },
  computed: {
    ...mapState("flowTasks", ["KANBAN"]),
    ...mapGetters("flowTasks", ["KANBAN_tasks"]),
    mockupCnt() {
      const { value } = this.status;
      const { total = 0 } = this.KANBAN[value] || {};
      const { length } = this.KANBAN_tasks[value] || [];

      return total - length < 0 ? 0 : total - length;
    }
  },
  methods: {
    ...mapActions("flowTasks", ["getTasks"])
  }
};
</script>
