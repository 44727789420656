<template>
  <v-textarea
    :rows="rows"
    ref="textRef"
    outlined
    no-resize
    auto-grow
    hide-details
    hide-spin-buttons
    :value="title"
    :loading="loading"
    :disabled="loading"
    placeholder="제목을 입력해주세요."
    @blur="blur"
    @keydown.esc.stop="blur"
    @keydown.enter.stop.prevent="e => $emit('enter', { e, changeEditable })"
    @input="v => $emit('input', v)"
  />
</template>

<style lang="scss" scoped>
.v-textarea::v-deep {
  fieldset {
    border-radius: 8px;
  }
  textarea {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin: 14px 0px 10px !important;
    line-height: 27px !important;
  }
}
</style>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.textRef.focus();
    }, 0);
  },
  methods: {
    focus() {
      this.$refs.textRef.focus();
    },
    blur() {
      if (this.loading) return;
      this.$emit("change:editable", false);
    },
    changeEditable() {
      this.$emit("change:editable", false);
    }
  }
};
</script>
