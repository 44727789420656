<template>
  <div class="cr-project-detail" :class="`cr-${column}`">
    <div class="d-flex align-center">
      <v-btn icon @click="showValue = !showValue">
        <v-icon>
          {{ showValue ? "mdi-chevron-down" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>

      <h4>{{ title }}</h4>
    </div>

    <div v-if="showValue" class="cr-text">
      {{ value }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-detail.cr-description {
  .cr-text {
    max-height: 200px;
    overflow-y: scroll;
    word-break: break-all;
    white-space: pre-line;
    padding: 7px 8px !important;
    align-items: flex-start !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    column: {
      type: String,
      default: ""
    }
  },
  data() {
    return { showValue: true };
  },
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    title() {
      switch (this.column) {
        case "title":
          return "프로젝트 이름";
        case "description":
          return "프로젝트 설명";
        default:
          return "";
      }
    },
    value() {
      return this.selectedProject[this.column] || "";
    }
  }
};
</script>
