<template>
  <div class="pr-4 py-3">
    <div class="d-flex align-center justify-space-between">
      <div class="text-h6 font-weight-black">프로젝트 정보</div>

      <div class="d-flex align-center">
        <EnterBtn v-if="isMember" />
        <ModifyBtn v-if="isMaster" />
        <DeleteBtn v-if="isMaster" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EnterBtn from "./EnterBtn.vue";
import ModifyBtn from "./ModifyBtn.vue";
import DeleteBtn from "./DeleteBtn.vue";

export default {
  components: { EnterBtn, ModifyBtn, DeleteBtn },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("flowProjects", ["selectedProject"]),
    isMaster() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject;
      const { userId } = members?.find(m => m.authority === "MASTER") || {};

      return userId == id;
    },
    isMember() {
      const { id: userId } = this.getUserInfo;
      const { members } = this.selectedProject;
      const { joinStatus } = members?.find(m => m.userId === userId) || {};

      return joinStatus && joinStatus === "ACCEPTED";
    }
  }
};
</script>
