<template>
  <v-tooltip top>
    <template v-slot:activator="{ attrs, on }">
      <v-btn icon small v-on="{ ...on, ...$listeners }" v-bind="attrs">
        <v-icon>mdi-tune</v-icon>
      </v-btn>
    </template>

    <span>검색 상세 설정</span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {}
};
</script>
