<template>
  <div
    class="cr-flow-list-dnd-wrapper"
    v-drag-and-drop="{
      DRAG_COMP: 'list',
      genHelper,
      dragstart,
      genDropzone,
      markDropzone,
      mouseup
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-list-dnd-wrapper::v-deep {
  width: 100%;
  height: calc(100% - 56px);
  .cr-draggable-list {
    &.cr-drop-target {
      cursor: grabbing !important;
      * {
        cursor: grabbing !important;
      }

      &.cr-item::after {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 1;
      }
    }
  }

  .cr-list-ghost {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      opacity: 0.12;
      background-color: var(--v-primary-base);
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["taskDataMap"]),
    ...mapGetters("dragDrop", ["dragComp"]),
    ...mapGetters("flowRoute", ["filterId", "searchWord"]),
    ...mapGetters("flowTasks", ["LIST_tasks"])
  },
  watch: {
    LIST_tasks() {
      this.$forceUpdate();
    }
  },
  methods: {
    ...mapActions("flowTasks", ["moveTask", "moveGroup"]),
    // drag & drop
    genHelper(e, setDragInfo) {
      if ((this.filterId || "all") !== "all" || this.searchWord) return;

      const { target } = e;
      if (!target || target.closest(".cr-drag-prevent")) return null;
      if (!target.closest(".cr-draggable-icon")) return null;

      const dragTarget = target.closest(".cr-draggable-list");
      if (!dragTarget) return null;

      const clsList = ["cr-drop-target", "cr-draggable-list"];
      const dragInfo = { ...dragTarget.dataset, dragTarget };
      const ghost = document.getElementById("crListGhost").cloneNode();
      switch (dragTarget.dataset.type) {
        case "task": {
          const node = dragTarget.querySelector(".cr-item");
          const cloneNode = node.cloneNode(true);
          cloneNode.classList.add("cr-not-hover");
          // 업무 row 체크박스 제거
          const div = document.createElement("div");
          div.style.width = "32px";
          const checkbox = cloneNode.querySelector(".cr-list-checkbox");
          checkbox?.parentNode?.replaceChild(div, checkbox);

          ghost.appendChild(cloneNode);
          ghost.setAttribute("data-type", dragTarget.dataset.type);

          dragInfo["ghost"] = ghost.cloneNode(true);
          dragInfo.ghost.classList.add(...[...clsList, "cr-list-ghost"]);
          break;
        }
        case "group": {
          ghost.classList.add("cr-group");

          const node = dragTarget.querySelector(".cr-group-title");
          const cloneNode = node.cloneNode(true);
          ghost.appendChild(cloneNode);
          ghost.setAttribute("data-type", dragTarget.dataset.type);

          dragInfo["ghost"] = ghost.cloneNode(true);
          dragInfo.ghost.classList.add(...[...clsList, "cr-list-ghost"]);
          break;
        }
        default:
          return null;
      }

      setDragInfo(dragInfo);
      ghost.classList.add("d-none");
      return ghost;
    },
    dragstart(e, setDragInfo, dragInfo) {
      document.body.classList.add("no-drop");
      dragInfo.dragTarget.classList.add("d-none");
    },
    genDropzone({ id, type }) {
      if (this.dragComp !== "list") return;
      if (!["task", "group"].includes(type)) return;

      // 커서 class 지정
      const draggableEls = this.$el.querySelectorAll(".cr-draggable-list");
      draggableEls?.forEach(el => el.classList.add("cr-drop-target"));

      // type:group - 업무, 업무 추가 인풋 제거
      let classList = ".dnd-tasks, .cr-bottom-section, .cr-task-add-input";
      if (type === "task") {
        const { subCnt } = this.taskDataMap[parseInt(id)] || {};
        if (!subCnt) return;

        classList = ".cr-sub-tasks-wrapper";
      }

      const removalEls = this.$el.querySelectorAll(classList);
      removalEls?.forEach(el => (el.style.display = "none"));
    },
    markDropzone(e, dragInfo) {
      if (this.dragComp !== "list") return;
      if (e.target.closest(".cr-list-ghost")) return;

      const { type, ghost } = dragInfo;
      const setInfo = (target, position, ghostParent) => {
        dragInfo["dropTarget"] = target;
        dragInfo["position"] = position;
        ghostParent[position](ghost);

        const crHelper = document.getElementById("crHelper");
        crHelper.classList.add("d-none");
        ghost.style.display = "block";
      };

      const target = e.target.closest(".cr-drop-target");
      if (!target) {
        const crHelper = document.getElementById("crHelper");
        crHelper.classList.remove("d-none");
        dragInfo["dropTarget"] = target;
        return (ghost.style.display = "none");
      }

      if (type === "task") {
        if (target.classList.contains("cr-task-group-wrapper")) {
          return setInfo(target, "after", target);
        }

        if (target.classList.contains("cr-bottom-section")) {
          return setInfo(target, "before", target);
        }

        // 하위 업무 영역
        if (target.classList.contains("cr-sub-tasks-wrapper")) {
          if (e.offsetY <= 10 && target?.firstChild) {
            setInfo(target.firstChild, "before", target.firstChild);
          } else if (target?.lastChild) {
            setInfo(target.lastChild, "after", target.lastChild);
          }
          return;
        }

        // 하위 업무 열려있는 상위업무
        const subTasksWrapper = target.querySelector(".cr-sub-tasks-wrapper");
        if (subTasksWrapper && subTasksWrapper.style.display !== "none") {
          if (e.offsetY >= 20) {
            const { firstChild } = subTasksWrapper;
            return setInfo(firstChild, "before", firstChild);
          }
        }
      }

      let position = "before";
      if (e.offsetY <= 10 && !target.classList.contains("cr-default-group")) {
        position = "after";
      }
      setInfo(target, position, target);
    },
    async mouseup(e, dragInfo) {
      const { type, dragTarget, dropTarget, position } = dragInfo;
      if (dropTarget) {
        const { id: taskId, gi: dragGi } = dragTarget.dataset;
        const { id: targetId, gi: targetKey } = dropTarget.dataset;

        if (type === "group") {
          await this.moveGroup({ dragGi, dropGi: targetKey, position });
        } else {
          await this.moveTask({ taskId, targetId, targetKey, position });
        }
      }

      document.body.classList.remove("no-drop");
      dragInfo.dragTarget.classList.remove("d-none");
      dragInfo.ghost?.parentNode?.removeChild(dragInfo.ghost);
      this.$el
        .querySelectorAll(".cr-sub-tasks-wrapper")
        .forEach(el => (el.style.display = ""));
      this.$el
        .querySelectorAll(".cr-drop-target")
        .forEach(el => el.classList.remove("cr-drop-target"));
      this.$el
        .querySelectorAll(".dnd-tasks, .cr-bottom-section, .cr-task-add-input")
        .forEach(el => (el.style.display = ""));
    }
  }
};
</script>
