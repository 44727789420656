<template>
  <v-btn icon :disabled="loading" @click="TOGGLE_COLLAPSED_GROUP(group.id)">
    <v-icon>
      {{ collapsed ? "mdi-chevron-right" : "mdi-chevron-down" }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("flowTasks", ["loading"])
  },
  methods: {
    ...mapMutations("flowTasks", ["TOGGLE_COLLAPSED_GROUP"])
  }
};
</script>
