<template>
  <div
    class="cr-project"
    :class="{ 'pl-1': idx % 2, 'pr-1': !(idx % 2), selected: isSelected }"
    @click="selectProject(project.id)"
  >
    <div class="cr-border">
      <FirstRow v-bind="$props" />
      <SecondRow v-bind="$props" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project::v-deep {
  position: relative;
  display: flex;
  width: 50%;
  height: 100px;
  margin-bottom: 8px;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    content: "";
  }
  &:hover::before,
  &.selected::before {
    background: rgba(0, 0, 0, 0.03);
  }
  &.pr-1:hover::before,
  &.pr-1.selected::before {
    left: 0px;
    right: 4px;
  }
  &.pl-1:hover::before,
  &.pl-1.selected::before {
    left: 4px;
    right: 0px;
  }

  .cr-border {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    width: 100%;
    border: thin solid rgba(0, 0, 0, 0.08);

    > .d-flex.justify-space-between > .d-flex.align-center {
      width: calc(100% - 60px);
    }
  }
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import FirstRow from "./firstRow";
import SecondRow from "./secondRow";

export default {
  components: { FirstRow, SecondRow },
  props: {
    idx: {
      type: Number,
      default: 0
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowProjects", ["selectedProjectId"]),
    isSelected() {
      return this.selectedProjectId == this.project?.id;
    }
  },
  methods: {
    ...mapActions("flowProjects", ["selectProject"])
  }
};
</script>
