<template>
  <Menu v-bind="$props">
    <template v-slot:activator="{ click }">
      <Activator v-bind="$props" @click="click" />
    </template>

    <template v-slot:menu-content="{ close }">
      <Content v-bind="$props" @change="date => changeRangeDate(date, close)" />
    </template>
  </Menu>
</template>

<script>
import Menu from "@/flow/views/components/commons/menu";
import Activator from "./activator";
import Content from "./content";

export default {
  components: { Menu, Activator, Content },
  emits: ["change:rangeDate"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeRangeDate(rangeDate, close) {
      close();
      this.$emit("change:rangeDate", rangeDate);
    }
  }
};
</script>
