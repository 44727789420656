<template>
  <div class="cr-detail-empty">
    <span class="subtitle-1 grey--text">
      <p>
        {{ selectedLoading ? "데이터 불러오는 중" : "선택된 항목이 없습니다." }}
      </p>

      <v-progress-linear
        v-if="selectedLoading"
        rounded
        indeterminate
        height="6"
        color="primary accent-4"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.cr-detail-empty {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding-top: 310px;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("flowProjects", ["selectedLoading"])
  }
};
</script>
