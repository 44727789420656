<template>
  <v-btn
    v-if="viewType === 'LIST'"
    text
    small
    outlined
    color="primary"
    class="ml-4"
    :disabled="loading || !hasEditPermission"
    @click="addGroup"
  >
    <v-icon size="16" class="mr-1">mdi-plus</v-icon>
    그룹만들기
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn::v-deep {
  height: 34px !important;
  border-radius: 8px;
  .v-btn__content {
    line-height: 14px;
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["loading"]),
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    ...mapGetters("flowRoute", ["viewType"])
  },
  methods: {
    ...mapActions("flowTasks", ["addGroup"])
  }
};
</script>
