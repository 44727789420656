<template>
  <div class="d-flex justify-end">
    <ExpandBtn />
    <!-- <DotsBtn /> -->
    <CloseBtn />
  </div>
</template>

<script>
import ExpandBtn from "./ExpandBtn.vue";
// import DotsBtn from "./DotsBtn.vue";
import CloseBtn from "./CloseBtn.vue";

export default {
  components: {
    ExpandBtn,
    // DotsBtn,
    CloseBtn
  }
};
</script>
