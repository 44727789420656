<template>
  <div class="cr-item-wrapper">
    <div
      v-for="{ component, value, icon, tooltip } in $props.components"
      :key="value"
      class="d-flex align-center"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="cr-item-icon">{{ icon }}</v-icon>
        </template>

        <span>{{ tooltip }}</span>
      </v-tooltip>
      <component :task="task" :is="component" :class="`py-1 task-${value}`" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-wrapper::v-deep {
  .task-status,
  .task-priority,
  .task-assignees,
  .task-startDate,
  .task-dueDate,
  .task-progress {
    width: 100%;
  }

  .task-status,
  .task-priority {
    .v-btn {
      border-radius: 8px !important;
    }
  }

  .cr-item-icon {
    height: 24px;
    margin-right: 8px;
  }
}
</style>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Array,
      default: () => [
        {
          value: "status",
          icon: "mdi-view-sequential",
          tooltip: "상태",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/status")
        },
        {
          value: "priority",
          icon: "mdi-priority-high",
          tooltip: "중요도",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/priority")
        },
        {
          value: "assignees",
          icon: "mdi-account",
          tooltip: "담당자",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/assignees")
        },
        {
          value: "startDate",
          icon: "mdi-calendar-month",
          tooltip: "시작일",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/startDate")
        },
        {
          value: "dueDate",
          icon: "mdi-calendar-month",
          tooltip: "마감일",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/dueDate")
        },
        {
          value: "progress",
          icon: "mdi-progress-helper",
          tooltip: "진척률",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/progress")
        }
      ]
    }
  }
};
</script>
