<template>
  <div class="cr-project-detail-content">
    <TextView v-for="column in columns" :key="column" :column="column" />
    <MemberView />
  </div>
</template>

<style lang="scss" scoped>
.cr-project-detail-content::v-deep {
  overflow: hidden scroll;
  width: 100%;
  flex-grow: 1;

  .cr-project-detail {
    margin-top: 12px;
    .cr-text {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      min-height: 40px;
      background-color: #fff;
      border: thin solid rgba(0, 0, 0, 0.08);
    }
  }
}
</style>

<script>
import TextView from "./TextView.vue";
import MemberView from "./memberView";

export default {
  components: { TextView, MemberView },
  data() {
    return { columns: ["title", "description"] };
  }
};
</script>
