<template>
  <Menu v-bind="$props">
    <template v-slot:activator="{ click }">
      <PriorityBtn :loading="loading" :priority="priority" @click="click" />
    </template>

    <template v-slot:menu-content="{ close }">
      <div class="cr-flow-priority-list">
        <div v-for="priority in priorities" :key="priority.value" class="mb-1">
          <PriorityBtn
            :priority="priority"
            @click="changePriority(priority, close)"
          />
        </div>
      </div>
    </template>
  </Menu>
</template>

<style lang="scss" scoped>
.cr-flow-priority-list {
  background: #fff;
  padding: 12px 8px 8px 8px;
}
</style>

<script>
import Menu from "@/flow/views/components/commons/menu";
import PriorityBtn from "./PriorityBtn.vue";

export default {
  components: { Menu, PriorityBtn },
  emits: ["change:priority"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      priorities: [
        { value: "LOW", title: "낮음", color: "#32CD32" },
        { value: "NORMAL", title: "보통", color: "#008000" },
        { value: "HIGH", title: "높음", color: "#FF642E" },
        { value: "URGENT", title: "긴급", color: "#DF2F4A" }
      ]
    };
  },
  computed: {
    priority() {
      const { priority = "NORMAL" } = this.task;
      return this.priorities.find(s => s.value === priority);
    }
  },
  methods: {
    changePriority(priority, close) {
      this.$emit("change:priority", priority.value);
      close();
    }
  }
};
</script>
