<template>
  <div class="d-flex align-center pb-1">
    <v-progress-linear height="14" :value="progressValue" />
    <div class="ml-2 cr-checklist-cnt">
      {{ `${checkedCnt}/${totalCnt}` }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-progress-linear {
  border-radius: 4px;
}
.cr-checklist-cnt {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
</style>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    checklist() {
      return this.task?.checklist || [];
    },
    checkedCnt() {
      return this.checklist.filter(c => c.checked).length;
    },
    totalCnt() {
      return this.checklist.length;
    },
    progressValue() {
      if (!this.checkedCnt / this.totalCnt) return 0;
      return (this.checkedCnt / this.totalCnt) * 100;
    }
  }
};
</script>
