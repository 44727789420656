<template>
  <div style="display: contents;">
    <v-text-field
      v-if="editable"
      ref="textRef"
      dense
      hide-details
      outlined
      :value="group.text"
      @blur="esc"
      @keydown.esc.stop="esc"
      @keydown.enter="enter"
    />
    <div
      v-else
      class="cr-group-title"
      :class="{ 'cr-default-group': isDefault }"
      @click="changeEditable"
    >
      {{ group.text }}
    </div>

    <v-spacer />
    <DotsBtn
      v-if="!isDefault"
      :group="group"
      @changeEditable="changeEditable"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  margin-bottom: 4px;
  font-weight: bold;
  fieldset {
    border-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
  }
}
.cr-group-title {
  display: flex;
  height: 40px;
  min-width: 400px;
  padding: 0px 11px;
  align-items: center;
  border: thin solid transparent;
  font-weight: bold;
  margin-bottom: 4px;
}
</style>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import DotsBtn from "./DotsBtn.vue";

export default {
  components: { DotsBtn },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { editable: false };
  },
  computed: {
    isDefault() {
      return this.group.isDefault === 1;
    }
  },
  methods: {
    ...mapActions("flowTasks", ["updateGroupTitle"]),
    changeEditable() {
      if (this.isDefault) return;

      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    esc() {
      this.editable = false;
      this.$refs.textRef.reset();
    },
    enter(e) {
      if (this.isDefault) return;

      const title = e.target.value.trim();
      if (isBlank(title)) return;

      const { id: groupId } = this.group;
      this.updateGroupTitle({ groupId, title });
      this.esc();
    }
  }
};
</script>
