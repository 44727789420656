<template>
  <div class="cr-left-btns">
    <ToggleBtns />
    <AddGroupBtn />
    <!-- <SettingBtn /> -->
  </div>
</template>

<style lang="scss" scoped>
.cr-left-btns {
  display: flex;
  align-items: flex-end;
  height: 48px;
}
</style>

<script>
import ToggleBtns from "./ToggleBtns.vue";
import AddGroupBtn from "./AddGroupBtn.vue";
// import SettingBtn from "./SettingBtn.vue";

export default {
  components: {
    ToggleBtns,
    AddGroupBtn
    // SettingBtn
  }
};
</script>
