<template>
  <div v-if="showMyProjects" class="cr-empty-wrapper">
    <div class="cr-h3">가입된 프로젝트가 없습니다.</div>
    <div class="cr-h3">
      새로운
      <span class="bold cursor" @click="addProject">
        프로젝트를 생성
      </span>
      하거나
    </div>
    <div class="cr-h3">
      <span class="bold cursor" @click="toggleShowMyProjects">
        프로젝트를 검색
      </span>
      해보세요.
    </div>
  </div>

  <div v-else-if="!searchWord" class="cr-empty-wrapper">
    <div class="cr-h3">생성된 프로젝트가 없습니다.</div>
    <div class="cr-h3">
      새로운
      <span class="bold cursor" @click="addProject">프로젝트를 생성</span>
      해주세요.
    </div>
  </div>

  <div v-else class="cr-empty-wrapper">
    <div class="cr-h3">검색결과가 없습니다.</div>
    <div class="cr-h3">
      <span class="bold">"{{ searchWord }}"</span> 로 새로운
      <span class="bold cursor" @click="addProject">프로젝트를 생성</span>
      해보세요.
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 250px;

  .cr-h3 {
    font-size: 1.1em;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
    .bold {
      color: var(--v-primary-darken2);
      font-weight: bold;
    }
    .cursor {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowProjects", ["searchWord", "showMyProjects"])
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapActions("flowProjects", ["toggleShowMyProjects"]),
    addProject() {
      const { searchWord: title } = this;
      const headline = "새 프로젝트 생성";
      const type = "addProject";
      const params = { project: { title } };
      this.SHOW_DIALOG({ headline, type, params });
    }
  }
};
</script>
