<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-chip small label :color="color" v-on="on" class="mb-1 mr-1">
        <div class="d-flex align-center">
          <v-icon v-if="member.authority == 'MASTER'" size="14">
            mdi-crown
          </v-icon>
          {{ userName }}
        </div>
      </v-chip>
    </template>

    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    userName() {
      return this.member?.userName || "";
    },
    tooltip() {
      const { organizationName, jobGrade, jobTitle } = this.member;
      return [organizationName, jobGrade, jobTitle].filter(Boolean).join(" / ");
    },
    isAccepted() {
      return this.member?.joinStatus === "ACCEPTED";
    },
    color() {
      return this.isAccepted ? "primary" : "";
    }
  }
};
</script>
