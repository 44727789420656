<template>
  <div class="cr-gantt px-3">
    간트차트
  </div>
</template>

<style lang="scss" scoped>
.cr-gantt {
  height: 100%;
  width: 100%;
}
</style>

<script>
export default {};
</script>
