<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on" class="mr-1" @click="SET_SEARCH_WORD('')">
        <v-icon size="16">mdi-close</v-icon>
      </v-btn>
    </template>

    <span>검색어 삭제</span>
  </v-tooltip>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations("flowProjects", ["SET_SEARCH_WORD"])
  }
};
</script>
