<template>
  <div style="display: contents;">
    <div class="cr-flow-detail-header-wrapper">
      <ParentTask />
      <ActionBtns />
    </div>

    <Title
      :key="isCollapsed"
      class="pb-2"
      :class="{ 'pl-5': !isCollapsed }"
      :task="task"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-detail-header-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding: 16px 12px;
  background-color: #fff;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import Title from "@/flow/views/components/tasks/attrs/withStore/title";
import ParentTask from "./parentTask";
import ActionBtns from "./btns";

export default {
  components: { Title, ParentTask, ActionBtns },
  computed: {
    ...mapState("flowTasks", { isCollapsed: "isDetailCollapsed" }),
    ...mapGetters("flowTasks", { task: "detailTask" })
  }
};
</script>
