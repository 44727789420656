<template>
  <div>
    <Status
      :task="task"
      :loading="loading"
      :noArrow="noArrow"
      :fitContent="fitContent"
      :disabled="!hasEditPermission"
      @change:status="changeStatus"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Status from "@/flow/views/components/commons/task/status";

export default {
  components: { Status },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateStatus"]),
    async changeStatus(status) {
      this.loading = true;
      const { id: taskId } = this.task;
      await this.updateStatus({ taskId, status });

      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }
};
</script>
