<template>
  <div class="cr-expanded-wrapper">
    <LeftPanel />
    <RightPanel />
  </div>
</template>

<style lang="scss" scoped>
.cr-expanded-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

<script>
import LeftPanel from "./LeftPanel.vue";
import RightPanel from "./RightPanel.vue";

export default {
  components: { LeftPanel, RightPanel }
};
</script>
