<template>
  <div
    class="cr-kanban-add-task-wrapper"
    :class="{ 'cr-scroll-move': isScroll, disabled: !hasEditPermission }"
  >
    <AddTaskInput
      v-if="hasEditPermission"
      :color="color"
      :disabled="disabled"
      @add:task="_addTask"
      @add:tasks="_addTasks"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-add-task-wrapper {
  background-color: #f5f5f5;
  padding: 8px 12px 0px;
  border: thin solid transparent;
  border-left-color: rgba(0, 0, 0, 0.12);
  border-right-color: rgba(0, 0, 0, 0.12);
  &.cr-scroll-move {
    padding-bottom: 8px;
  }
  &.disabled {
    padding: 0px !important;
  }
}
.v-text-field.cr-task-add-input {
  border-radius: 12px !important;
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AddTaskInput from "@/flow/views/components/commons/addTaskInput";

export default {
  components: { AddTaskInput },
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", { disabled: "loading" }),
    ...mapGetters("flowTasks", ["defaultGroupId"]),
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    color() {
      return this.status?.color || "";
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["RESET_NEW_TASK", "SET_NEW_TASK"]),
    ...mapActions("flowTasks", ["addTask", "addTasks"]),
    // 단건 등록
    async _addTask({ resolve, title }) {
      this.RESET_NEW_TASK();
      const { value: status } = this.status;
      this.SET_NEW_TASK({ title, status, groupId: this.defaultGroupId });
      await this.addTask();

      resolve();
    },
    // 여러개 등록
    async _addTasks({ resolve, titleList }) {
      const { value: status } = this.status;
      await this.addTasks({ titleList, status, groupId: this.defaultGroupId });

      resolve();
    }
  }
};
</script>
