<template>
  <div class="cr-header-wrapper">
    <div class="cr-header" :style="style">
      {{ status.title }}
    </div>
    <AddTask v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-header-wrapper {
  display: flex;
  flex-direction: column;
  .cr-header {
    display: flex;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    min-height: 40px;
    border-radius: 12px 12px 0px 0px;
    align-items: center;
    justify-content: center;
  }
}
</style>

<script>
import AddTask from "./AddTask.vue";

export default {
  components: { AddTask },
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      const { color } = this.status;
      return `background-color: ${color};`;
    }
  }
};
</script>
