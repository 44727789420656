<template>
  <div class="cr-project-wrapper" @scroll="scroll">
    <Empty v-if="isEmpty" />
    <div v-else class="d-flex flex-wrap flex-column">
      <Partition v-for="joinStatus in joinStatusList" :key="joinStatus" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-wrapper {
  overflow-y: scroll;
  position: absolute;
  top: 54px;
  left: 0px;
  right: -16px;
  bottom: 0px;
}
</style>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Partition from "./partition";
import Empty from "./empty";

export default {
  components: { Partition, Empty },
  mounted() {
    const target = document.querySelector(".cr-infinity-loader");
    const observer = new IntersectionObserver(async entries => {
      for (const entry of entries) {
        if (!entry.isIntersecting || !this.nextPageExists || this.isLoadingList)
          continue;

        this.isLoadingList = true;
        await this[this.showMyProjects ? "getMyProjects" : "getAllProjects"]();
        this.isLoadingList = false;
      }
    });
    observer.observe(target);
  },
  data() {
    return {
      isLoadingList: false,
      joinStatusList: ["INVITED", "PENDING", "ACCEPTED"]
    };
  },
  computed: {
    ...mapState("flowProjects", [
      "loading",
      "nextPageExists",
      "showMyProjects"
    ]),
    ...mapGetters("flowProjects", ["projects"]),
    isEmpty() {
      return !this.projects.length && !this.loading;
    }
  },
  methods: {
    ...mapActions("flowProjects", ["getMyProjects", "getAllProjects"]),
    async scroll(e) {
      if (!this.nextPageExists) return;
      if (this.isLoadingList) return;

      const { offsetHeight, scrollTop, scrollHeight } = e.target;
      if (scrollHeight - offsetHeight - 150 > scrollTop) return;

      this.isLoadingList = true;
      await this[this.showMyProjects ? "getMyProjects" : "getAllProjects"]();
      this.isLoadingList = false;
    }
  }
};
</script>
