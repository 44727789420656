<template>
  <div
    :id="`task_${task.id}`"
    :data-id="task.id"
    :data-st="task.status"
    class="cr-item cr-draggable-kanban"
    :class="{ 'cr-selected': isSelected }"
  >
    <DragIcon :task="task" />
    <Title :task="task" />
    <Item :task="task" />
  </div>
</template>

<style lang="scss" scoped>
.cr-item::v-deep {
  position: relative;
  background: #fff;
  margin: 6px 0px;
  padding: 8px 12px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  &.cr-selected::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    background: var(--v-primary-base);
    opacity: 0.08;
    border-radius: 8px;
  }
  &:not(.cr-drop-target):hover {
    .cr-draggable-icon {
      display: flex;
      &:hover {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import Title from "./Title.vue";
import DragIcon from "./DragIcon.vue";
import Item from "./Item.vue";

export default {
  components: { Title, DragIcon, Item },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", ["detailTaskId"]),
    isSelected() {
      return this.detailTaskId === this.task.id;
    }
  }
};
</script>
