<template>
  <v-textarea
    ref="textRef"
    dense
    outlined
    no-resize
    hide-details
    hide-spin-buttons
    :rows="1"
    :color="color"
    :loading="loading"
    :disabled="loading || disabled"
    class="cr-task-add-input"
    :class="{ 'cr-focused': focused }"
    placeholder="새로운 업무 추가"
    prepend-inner-icon="mdi-plus"
    v-model="model"
    @input="change"
    @keydown.enter="enter"
    @focus="focused = true"
    @blur="focused = false"
  />
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  border-radius: 8px !important;
  border: thin solid transparent;
  &.cr-focused {
    background: rgba(0, 0, 0, 0.08);
    border-color: rgba(0, 0, 0, 0.12);
  }
  fieldset {
    border: none !important;
  }
}
</style>

<script>
import { isBlank } from "@/commons/utils/validation";

export default {
  emits: ["add:task", "add:tasks"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      model: "",
      loading: false,
      focused: false,
      pressEnter: false
    };
  },
  methods: {
    isBlank(title) {
      const regex = /\r\n|\\n|\n/gi;
      if (!title) return true;
      return isBlank(title.trim().replace(regex, ""));
    },
    blurAction() {
      this.$refs.textRef.reset();
      this.$refs.textRef.blur();
      setTimeout(() => {
        this.model = "";
      }, 0);
    },
    // 단건 등록
    async enter(e) {
      const title = e.target.value;
      this.pressEnter = true;
      this.blurAction();
      if (this.isBlank(title)) return (this.pressEnter = false);

      this.loading = true;
      await new Promise(resolve => this.$emit("add:task", { resolve, title }));

      this.loading = false;
      this.pressEnter = false;

      setTimeout(() => {
        this.$refs.textRef.focus();
      }, 0);
    },
    // 여러개 등록
    async change(txt) {
      const regex = /\r\n|\\n|\n/gi;

      txt = txt?.trim();
      if (!txt || this.pressEnter) return;
      if (!regex.test(txt)) return;

      this.blurAction();
      const separator = "<;:;>";
      const titleList = txt.replace(regex, separator).split(separator);
      this.loading = true;

      await new Promise(resolve =>
        this.$emit("add:tasks", { resolve, titleList })
      );

      this.loading = false;
      this.pressEnter = false;

      setTimeout(() => {
        this.$refs.textRef.focus();
      }, 0);
    }
  }
};
</script>
