<template>
  <v-icon v-if="showDragIcon" class="cr-draggable-icon" size="28">
    mdi-drag-horizontal
  </v-icon>
</template>

<style lang="scss" scoped>
.cr-draggable-icon {
  cursor: grab;
  display: none;
  position: absolute;
  top: -12px;
  left: calc(50% - 14px);
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    ...mapGetters("flowRoute", ["filterId", "searchWord"]),
    showDragIcon() {
      const { filterId = "all", searchWord, task = {} } = this;
      return (
        filterId === "all" &&
        !searchWord &&
        !task?.parentId &&
        this.hasEditPermission
      );
    }
  }
};
</script>
