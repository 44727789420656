<template>
  <div style="display: contents;">
    <EditText
      ref="editText"
      v-if="editable"
      v-bind="$props"
      v-on="$listeners"
      @changeEditable="changeEditable"
    />
    <DisplayText
      v-else
      v-bind="$props"
      v-on="$listeners"
      @changeEditable="changeEditable"
    />
  </div>
</template>

<script>
import DisplayText from "./DisplayText.vue";
import EditText from "./EditText.vue";

export default {
  components: { DisplayText, EditText },
  emits: ["enter"],
  props: {
    mode: {
      type: String,
      default: "view"
    },
    task: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data({ mode }) {
    return { editable: mode !== "view" };
  },
  methods: {
    focus() {
      if (!this.editable || !this.$refs.editText) return;
      this.$refs.editText.focus();
    },
    changeEditable(editable) {
      if (this.mode !== "view" || this.disabled) return;
      this.editable = editable;
    }
  }
};
</script>
