<template>
  <div class="cr-kanban-board-loader">
    <v-skeleton-loader type="image" />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-board-loader::v-deep {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 12px 12px 0px 0px;
  background-color: #fff;
  .v-skeleton-loader {
    height: 40px;
    border-radius: 12px 12px 0px 0px;
  }
}
</style>

<script>
export default {};
</script>
