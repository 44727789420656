<template>
  <v-btn
    v-if="isJoined && !isPending"
    icon
    small
    :color="color"
    :loading="loading"
    :disabled="loading"
    @click.stop="favoriteProject"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    icon() {
      const { favoriteStatus } = this.project;
      if (favoriteStatus) return "mdi-star";
      return "mdi-star-outline";
    },
    color() {
      const { favoriteStatus } = this.project;
      if (!favoriteStatus) return "";
      return "yellow";
    },
    loginUserJoinStatus() {
      if (!this.project) return null;

      const { id: userId } = this.getUserInfo;
      const member = this.project?.members?.find(m => m.userId == userId);
      if (!member) return null;

      return member.joinStatus;
    },
    isJoined() {
      return !!this.loginUserJoinStatus;
    },
    isPending() {
      return this.isJoined && this.loginUserJoinStatus !== "ACCEPTED";
    }
  },
  methods: {
    ...mapActions("flowProjects", ["updateFavoriteStatus"]),
    async favoriteProject() {
      let { favoriteStatus } = this.project;
      favoriteStatus = favoriteStatus ? 0 : 1;

      this.loading = true;
      const { id: projectId } = this.project;
      await this.updateFavoriteStatus({ projectId, favoriteStatus });
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }
};
</script>
