<template>
  <v-btn
    small
    depressed
    color="accent"
    class="ml-2"
    @click="$emit('addSubTask')"
  >
    추가
  </v-btn>
</template>

<script>
export default {};
</script>
