<template>
  <Assignee
    mode="icon"
    :task="task"
    @click:member="member => $emit('click:member', { task, member })"
  />
</template>

<style lang="scss" scoped>
::v-deep {
  .v-btn.cr-icon-btn {
    margin-top: -5px;
    min-width: 36px !important;
  }
}
</style>

<script>
import Assignee from "@/flow/views/components/commons/task/assignee";

export default {
  components: { Assignee },
  emits: ["click:member"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
