<template>
  <div style="display: contents;">
    <EditText
      v-if="editable"
      ref="editText"
      v-bind="$props"
      v-on="$listeners"
      :rows="rows"
      @change:editable="changeEditable"
    />
    <DisplayText
      v-else
      v-bind="$props"
      v-on="$listeners"
      :rows.sync="rows"
      @change:editable="changeEditable"
    />
  </div>
</template>

<script>
import EditText from "./EditText.vue";
import DisplayText from "./DisplayText.vue";

export default {
  components: { EditText, DisplayText },
  emits: ["click:display", "enter"],
  model: { prop: "title" },
  props: {
    title: {
      type: String,
      default: ""
    },
    mode: {
      // default, display, edit
      type: String,
      default: "default"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data({ mode }) {
    return {
      rows: 1,
      editable: mode === "edit"
    };
  },
  methods: {
    focus() {
      if (!this.editable || !this.$refs.editText) return;
      this.$refs.editText.focus();
    },
    changeEditable(editable) {
      if (this.mode !== "default" || this.disabled) return;
      this.editable = editable;
    }
  }
};
</script>
