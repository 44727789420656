<template>
  <v-list dense class="pa-0 py-1">
    <member
      v-for="(member, index) in members"
      :key="index"
      :member="member"
      v-bind="$props"
      v-on="$listeners"
    />

    <v-list-item v-if="!members.length" class="mt-3">
      <v-list-item-content>
        <v-list-item-title>멤버가 없습니다.</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<style lang="scss" scoped>
.v-list::v-deep {
  min-height: 56px;
  max-height: 240px;
  overflow: auto;

  .v-list-item--active::before {
    opacity: 0;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Member from "./Member.vue";

export default {
  components: { Member },
  props: {
    searchWord: {
      type: String,
      default: ""
    },
    assignees: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    members() {
      const { members } = this.selectedProject;
      if (!members) return [];
      if (!this.searchWord) return members;
      return members.filter(m => m.userName.includes(this.searchWord));
    }
  }
};
</script>
