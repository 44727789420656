<template>
  <v-chip small color="primary" class="px-2 mr-1 mt-1">
    {{ assignee.userName }}
  </v-chip>
</template>

<style lang="scss" scoped>
.v-chip::v-deep {
  cursor: pointer;
  .v-chip__content {
    display: inline;
    padding-top: 2px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
</style>

<script>
export default {
  props: {
    assignee: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
