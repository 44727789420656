<template>
  <div class="cr-status-wrapper px-2">
    <div class="cr-sub-title">
      상태
    </div>

    <Status noArrow fitContent :task="task" />
  </div>
</template>

<style lang="scss" scoped>
.cr-status-wrapper {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100%;
  max-width: 50%;
}
</style>

<script>
import { mapGetters } from "vuex";
import Status from "@/flow/views/components/tasks/attrs/withStore/status";

export default {
  components: { Status },
  computed: {
    ...mapGetters("flowTasks", { task: "detailTask" })
  }
};
</script>
