<template>
  <div class="cr-menu-activator-wrapper" :class="{ show, disabled }">
    <slot name="activator" :click="click" />

    <portal v-if="show" to="flowMenu">
      <Content
        v-bind="$props"
        :targetRect="targetRect"
        @close="cancelTrackElement"
      >
        <slot name="menu-content" :close="cancelTrackElement" />
      </Content>
    </portal>
  </div>
</template>

<style lang="scss" scoped>
.cr-menu-activator-wrapper::v-deep {
  &.disabled {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}
</style>

<script>
import Content from "./Content.vue";

export default {
  components: { Content },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // 화면에서 사라지면 메뉴 close
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) continue;

        this.cancelTrackElement();
      }
    });
    observer.observe(this.$el);
  },
  data() {
    return {
      show: false,
      trackingId: null,
      targetRect: {}
    };
  },
  methods: {
    click() {
      if (this.show || this.disabled) {
        return this.cancelTrackElement();
      }

      this.targetRect = this.$el.getBoundingClientRect();
      this.show = true;
      this.trackElement(this.$el);
    },
    trackElement(element) {
      this.trackingId = requestAnimationFrame(() => this.trackElement(element));

      const { top, left } = this.targetRect;
      const currentRect = element.getBoundingClientRect();
      if (top === currentRect.top && left === currentRect.left) return;

      this.targetRect = currentRect;
    },
    cancelTrackElement() {
      if (!this.trackingId) return;

      cancelAnimationFrame(this.trackingId);
      this.trackingId = null;
      this.show = false;
    },
    updateDimensions() {
      this.$nextTick(() => {
        this.targetRect = this.$el.getBoundingClientRect();
      });
    }
  }
};
</script>
