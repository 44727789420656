<template>
  <v-dialog
    v-model="model"
    persistent
    hide-overlay
    no-click-animation
    :transition="false"
    :retain-focus="false"
  >
    <div class="cr-flow-task-action-menu-wrapper">
      <div class="d-flex">
        <div class="mr-2">선택된 업무</div>
        <div>{{ checkedTasks.length }} 개</div>
      </div>
      <div>
        <!-- 그룹 이동 -->
        <v-btn icon disabled>
          <v-icon>mdi-folder-move</v-icon>
        </v-btn>
        <!-- 프로젝트 이동 -->
        <v-btn icon disabled>
          <v-icon>mdi-file-move</v-icon>
        </v-btn>
        <!-- 삭제 -->
        <v-btn icon @click="_deleteTasks">
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        <!-- 닫기 -->
        <v-btn icon @click="model = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog__content.v-dialog__content--active::v-deep {
  .v-dialog {
    position: absolute;
    bottom: 40px;
    width: 850px;
    height: 60px;
    overflow-y: hidden;

    .cr-flow-task-action-menu-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 8px 16px;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["checkedTasks"]),
    ...mapGetters("flowTasks", ["LIST_total"]),
    model: {
      get() {
        return this.checkedTasks.length > 0;
      },
      set(model) {
        if (model) return;
        this.SET_CHECKED_TASKS([]);
      }
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_CHECKED_TASKS"]),
    ...mapActions("flowTasks", ["deleteTasks", "deleteAllTask"]),
    _deleteTasks() {
      const { length: checkedCnt } = this.checkedTasks;
      if (!this.LIST_total || !checkedCnt) return;
      if (checkedCnt === this.LIST_total) {
        return this.deleteAllTask();
      }

      const callback = () => this.SET_CHECKED_TASKS([]);
      const tasks = this.checkedTasks.map(t => ({ taskId: t.id, ...t }));
      this.deleteTasks({ tasks, callback });
    }
  }
};
</script>
