<template>
  <div class="d-flex align-center justify-space-between mr-2">
    <span class="d-flex align-center gey--text darken-2">
      <PrivateStatus v-bind="$props" />
      <MasterAndMembers v-bind="$props" />
    </span>

    <JoinBtn v-bind="$props" />
  </div>
</template>

<script>
import PrivateStatus from "./PrivateStatus.vue";
import MasterAndMembers from "./MasterAndMembers.vue";
import JoinBtn from "./JoinBtn.vue";

export default {
  components: { PrivateStatus, MasterAndMembers, JoinBtn },
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
