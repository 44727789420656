<template>
  <div class="cr-task-header">
    <Checkbox />
    <div
      v-for="{ title, value } in items"
      :key="title"
      :class="`grey--text px-3 py-2 text-center task-${value}`"
    >
      {{ title }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-task-header {
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 3;
  height: 49px;
  width: max-content;
  min-width: 100%;
  background: #fff;
  padding: 4px 0px 4px 14px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
import Checkbox from "./Checkbox.vue";

export default {
  components: { Checkbox },
  data() {
    return {
      items: [
        { title: "번호", value: "number" },
        { title: "업무명", value: "title" },
        { title: "상태", value: "status" },
        { title: "우선순위", value: "priority" },
        { title: "담당자", value: "assignees" },
        { title: "작성자", value: "creator" },
        { title: "시작일", value: "startDate" },
        { title: "마감일", value: "dueDate" },
        { title: "진행률", value: "progress" }
      ]
    };
  }
};
</script>
