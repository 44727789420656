<template>
  <v-dialog
    scrollable
    :value="show"
    :max-width="maxWidth"
    :retain-focus="false"
    :content-class="contentCls"
    @input="CLOSE_DIALOG"
  >
    <v-card>
      <DialogTitle />
      <DialogContent :style="contentStyle" />
      <DialogActions />
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    width: auto !important;
    min-width: 400px;
    overflow: hidden;
    .v-card > .v-card__text {
      overflow: auto;
    }

    &.cr-add-task-dialog {
      width: 1200px !important;
      height: 100%;
    }
  }
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import DialogTitle from "./title";
import DialogContent from "./content";
import DialogActions from "./actions";

export default {
  components: { DialogTitle, DialogContent, DialogActions },
  computed: {
    ...mapState("flowDialog", ["show", "type", "params"]),
    maxWidth() {
      switch (this.type) {
        case "manageMember":
          return 600;
        case "addProject":
          return 800;
        case "addTask":
          return 1200;
        default:
          return 400;
      }
    },
    contentCls() {
      switch (this.type) {
        case "addTask":
          return "cr-add-task-dialog";
        default:
          return "";
      }
    },
    contentStyle() {
      switch (this.type) {
        case "delegateProject":
          return "max-height: 400px;";
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["CLOSE_DIALOG"])
  }
};
</script>
