<template>
  <div
    :id="`scrollEl_${status.value}`"
    class="cr-kanban-board-content"
    :class="{ 'cr-scroll-move': isScroll }"
    @scroll="scroll"
  >
    <Task v-for="task in tasks" :key="task.id" :task="task" />
    <MockupContainer v-bind="$props" :key="tasks.length" />
    <BottomSection v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-board-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow-y: scroll;
  background-color: #f5f5f5;
  padding: 6px 0px 0px 10px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-top: 0px;

  &::-webkit-scrollbar {
    width: 11px; /* 스크롤바의 너비 */
    height: 8px; /* 가로 스크롤바의 높이 */
  }
  &.cr-scroll-move {
    box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.25);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Task from "./task";
import MockupContainer from "./mockupContainer";
import BottomSection from "./bottomSection";

export default {
  components: { Task, MockupContainer, BottomSection },
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["KANBAN_tasks"]),
    tasks() {
      const { value } = this.status;
      return this.KANBAN_tasks[value] || [];
    }
  },
  methods: {
    scroll(e) {
      this.$emit("update:isScroll", e.target.scrollTop > 10);
    }
  }
};
</script>
