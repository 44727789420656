var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-item-wrapper"},_vm._l((_vm.$props.components),function(ref){
var component = ref.component;
var value = ref.value;
var icon = ref.icon;
var tooltip = ref.tooltip;
return _c('div',{key:value,staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"cr-item-icon"},on),[_vm._v(_vm._s(icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(tooltip))])]),_c(component,{tag:"component",class:("py-1 task-" + value),attrs:{"task":_vm.task}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }