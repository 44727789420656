<template>
  <div class="cr-right-panel-wrapper">
    <StatusAndPriority />
    <InfoContainer />
  </div>
</template>

<style lang="scss" scoped>
.cr-right-panel-wrapper::v-deep {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 350px;

  .cr-title {
    font-weight: bold;
    font-size: 14px;
    padding: 16px 20px 12px;
  }
}
</style>

<script>
import StatusAndPriority from "../commons/statusAndPriority";
import InfoContainer from "../commons/infoContainer";

export default {
  components: { StatusAndPriority, InfoContainer }
};
</script>
