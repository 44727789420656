<template>
  <div class="cr-task-checklist-wrapper py-4 px-5">
    <h4 class="black--text mb-4">체크리스트</h4>
    <Checklist
      :task="detailTask"
      :disabled="!hasEditPermission"
      @add:checklist="_addChecklist"
      @update:checklist="_updateChecklist"
      @delete:checklist="_deleteChecklist"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-checklist-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 8px;
  background-color: #fff;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Checklist from "@/flow/views/components/commons/task/checklist";

export default {
  components: { Checklist },
  computed: {
    ...mapGetters("flowTasks", ["detailTask", "hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", [
      "addChecklist",
      "updateChecklist",
      "deleteChecklist"
    ]),
    _addChecklist(checklist) {
      const { id: taskId } = this.detailTask;
      this.addChecklist({ taskId, checklist });
    },
    _updateChecklist(checklist) {
      const { id: taskId } = this.detailTask;
      this.addChecklist({ taskId, checklist });
    },
    _deleteChecklist({ id: checklistId }) {
      const { id: taskId } = this.detailTask;
      this.deleteChecklist({ taskId, checklistId });
    }
  }
};
</script>
