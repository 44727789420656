<template>
  <v-dialog
    v-model="model"
    no-click-animation
    :transition="false"
    :retain-focus="false"
    :persistent="isDetailCollapsed"
    :fullscreen="isDetailCollapsed"
    :hide-overlay="isDetailCollapsed"
    :content-class="isDetailCollapsed ? 'cr-collapsed' : ''"
  >
    <slot />
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog__content.v-dialog__content--active::v-deep {
  .v-dialog {
    width: 1200px;
    height: 100%;
    overflow-y: hidden;

    &.cr-collapsed {
      left: unset;
      right: 0px;
      width: 500px;
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["isDetailCollapsed"]),
    ...mapGetters("flowTasks", ["detailTask"]),
    model: {
      get() {
        return !!this.detailTask;
      },
      set(model) {
        if (model) return;
        this.RESET_DETAIL_TASK_ID();
      }
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["RESET_DETAIL_TASK_ID"])
  }
};
</script>
