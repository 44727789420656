<template>
  <v-tooltip :top="!isDetailCollapsed" :bottom="isDetailCollapsed">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="RESET_DETAIL_TASK_ID">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </template>

    <span>닫기</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["isDetailCollapsed"])
  },
  methods: {
    ...mapMutations("flowTasks", ["RESET_DETAIL_TASK_ID"])
  }
};
</script>
