<template>
  <div v-on="$listeners">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon outlined :color="color">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>

      <div>{{ tooltip }}</div>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.v-btn::v-deep {
  height: auto !important;
  min-height: 40px !important;
  padding: 0 12px !important;
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.12);
  margin-top: -5px;
  margin-right: 4px;
  margin-left: 4px;

  &:focus::before {
    opacity: 0 !important;
  }
}
</style>

<script>
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isActive() {
      const { startDateTimeMillis: start, dueDateTimeMillis: due } = this.task;
      return !!start && !!due;
    },
    tooltip() {
      const { startDateTimeMillis: start, dueDateTimeMillis: due } = this.task;
      if (!start && !due) return "기간 설정";
      return `${getDateWithoutTime(start)} ~ ${getDateWithoutTime(due)}`;
    },
    color() {
      if (!this.isActive) return "";
      return "primary";
    }
  }
};
</script>
