<template>
  <AddTaskInput
    :disabled="disabled"
    @add:task="_addTask"
    @add:tasks="_addTasks"
  />
</template>

<style lang="scss" scoped>
.v-text-field.cr-task-add-input {
  margin-left: 36px;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddTaskInput from "@/flow/views/components/commons/addTaskInput";

export default {
  components: { AddTaskInput },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", { disabled: "loading" })
  },
  methods: {
    ...mapMutations("flowTasks", ["RESET_NEW_TASK", "SET_NEW_TASK"]),
    ...mapActions("flowTasks", ["addTask", "addTasks"]),
    // 단건 등록
    async _addTask({ resolve, title }) {
      this.RESET_NEW_TASK();
      const { id: groupId } = this.group;
      this.SET_NEW_TASK({ title, groupId });
      await this.addTask();

      resolve();
    },
    // 여러개 등록
    async _addTasks({ resolve, titleList }) {
      const { id: groupId } = this.group;
      await this.addTasks({ titleList, groupId });

      resolve();
    }
  }
};
</script>
