<template>
  <div style="width: 100%;">
    <ToggleBtn v-if="showBtn" :key="$vnode.key" v-bind.sync="$data" />
    <div v-if="showList" class="d-flex flex-wrap">
      <ProjectItem
        v-for="(project, idx) in _projects"
        :key="project.id"
        :idx="idx"
        :project="project"
      />
    </div>

    <InfinityLoader v-if="renderInfinityLoader" v-show="showInfinityLoader" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ToggleBtn from "./ToggleBtn.vue";
import ProjectItem from "../item";
import InfinityLoader from "@/flow/views/components/commons/infinityLoader";

export default {
  components: { ToggleBtn, ProjectItem, InfinityLoader },
  data() {
    return { showList: true };
  },
  computed: {
    ...mapState("flowProjects", [
      "page",
      "loading",
      "showMyProjects",
      "nextPageExists"
    ]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("flowProjects", ["projects"]),
    showBtn() {
      const { key } = this.$vnode;
      const { length } = this._projects;
      const { id } = this.getUserInfo;

      if (key !== "ACCEPTED") return !!length && this.showMyProjects;
      return (
        !!length &&
        this.showMyProjects &&
        this.projects.some(
          p => p.members.find(m => m.userId === id)?.joinStatus !== key
        )
      );
    },
    renderInfinityLoader() {
      return this.$vnode.key === "ACCEPTED";
    },
    showInfinityLoader() {
      const { length } = this.projects;
      return this.nextPageExists && length && !this.loading && this.showList;
    },
    _projects() {
      const { key } = this.$vnode;
      const { id } = this.getUserInfo;

      if (!this.showMyProjects && key !== "ACCEPTED") return [];
      if (!this.showMyProjects) return this.projects;
      return this.projects
        .filter(p => p.members.find(m => m.userId === id)?.joinStatus === key)
        .sort((a, b) => a.sortOrder - b.sortOrder);
    }
  },
  watch: {
    projects() {
      if (this.page) return;
      this.showList = true;
    }
  }
};
</script>
