<template>
  <v-tooltip top :disabled="!deleteBtnDisabled">
    <template v-slot:activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on">
        <v-btn
          small
          outlined
          color="red"
          height="24"
          :disabled="deleteBtnDisabled"
          @click="deleteProject(projectId)"
        >
          삭제
        </v-btn>
      </div>
    </template>

    <span>참여 중인 멤버가 없을 때만 가능합니다.</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    projectId() {
      return this.selectedProject.id;
    },
    deleteBtnDisabled() {
      const { members } = this.selectedProject;
      return !!members?.filter(
        m => m.authority !== "MASTER" && m.joinStatus === "ACCEPTED"
      )?.length;
    }
  },
  methods: {
    ...mapActions("flowProjects", ["deleteProject"])
  }
};
</script>
