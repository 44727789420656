<template>
  <div class="cr-parent-task-wrapper">
    <div v-if="show" @click="showTaskDetail(parentTask)">
      <span class="cr-label">
        상위 업무 <v-icon size="16">mdi-swap-horizontal</v-icon>
      </span>
      <div class="cr-parent-title text-truncate" :title="parentTask.title">
        {{ parentTask.title }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-parent-task-wrapper {
  padding-left: 6px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  max-width: calc(100% - 120px);

  .cr-label {
    position: absolute;
    top: 6px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: 500;
  }
  &:hover {
    cursor: pointer;
    .cr-parent-title {
      text-decoration: underline;
      color: var(--v-primary-base);
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["taskDataMap"]),
    ...mapGetters("flowTasks", ["detailTask"]),
    show() {
      const { parentId } = this.detailTask;
      return parentId > 0;
    },
    parentTask() {
      const { parentId } = this.detailTask;
      return this.taskDataMap[parentId] || {};
    }
  },
  methods: {
    ...mapActions("flowTasks", ["showTaskDetail"])
  }
};
</script>
