<template>
  <div class="cr-project-search-wrapper" :class="cls">
    <SearchBtn />
    <TextField :expand="expand" @update:expand="v => (expand = v)" />
  </div>
</template>

<style lang="scss" scoped>
.cr-project-search-wrapper::v-deep {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  overflow: hidden;
  position: relative;

  &.show .cr-search-btn.v-btn {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
  }
  &.show .cr-search-field.v-text-field {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  &.expanded {
    height: 110px;
    z-index: 1;
    background: #fff;
  }
}
</style>

<script>
import { mapState } from "vuex";
import TextField from "./field";
import SearchBtn from "./searchBtn";

export default {
  components: { TextField, SearchBtn },
  data() {
    return { expand: false };
  },
  computed: {
    ...mapState("flowProjects", ["showMyProjects"]),
    cls() {
      return { show: !this.showMyProjects, expanded: this.expand };
    }
  }
};
</script>
