<template>
  <div
    class="cr-flow-kanban-dnd-wrapper"
    v-drag-and-drop="{
      DRAG_COMP: 'kanban',
      genHelper,
      dragstart,
      genDropzone,
      markDropzone,
      mouseup
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-kanban-dnd-wrapper::v-deep {
  display: flex;
  width: 100%;
  .cr-drop-cursor,
  .cr-draggable-kanban.cr-drop-target {
    cursor: grabbing !important;
    * {
      cursor: grabbing !important;
    }
  }

  .cr-drag-target {
    display: none !important;
  }

  .cr-drop-target {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }

  // 칸반 보드 고스트
  .cr-kanban-ghost {
    display: flex;
    flex-direction: column;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 0px;
      right: 0px;
      opacity: 0.12;
      background-color: var(--v-accent-base);
      border-radius: 8px;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("dragDrop", ["dragComp"]),
    ...mapGetters("flowRoute", ["filterId", "searchWord"]),
    ...mapGetters("flowTasks", ["KANBAN_tasks"])
  },
  watch: {
    KANBAN_tasks() {
      this.$nextTick(() => {
        this.genDropzone();
      });
    }
  },
  methods: {
    ...mapActions("flowTasks", ["moveTask"]),
    // drag & drop
    genHelper(e, setDragInfo) {
      if ((this.filterId || "all") !== "all" || this.searchWord) return;

      const { target } = e;
      if (!target || target.closest(".cr-drag-prevent")) return null;
      if (!target.closest(".cr-draggable-icon")) return null;

      const dragTarget = target.closest(".cr-draggable-kanban");
      if (!dragTarget) return null;

      // 마우스 따라다니는 고스트
      const ghost = document.createElement("div");
      const clsList = ["cr-drop-target", "cr-draggable-kanban"];
      const dragInfo = { ...dragTarget.dataset, dragTarget };
      const cloneNode = dragTarget.cloneNode(true);
      const { width } = dragTarget.getBoundingClientRect();
      cloneNode.classList.add("cr-drag-ghost");
      cloneNode.style.width = width + "px";
      ghost.appendChild(cloneNode);

      dragInfo["ghost"] = ghost.cloneNode(true);
      dragInfo.ghost.classList.add(...[...clsList, "cr-kanban-ghost"]);

      setDragInfo(dragInfo);
      ghost.classList.add("d-none");
      return ghost;
    },
    dragstart(e, setDragInfo, dragInfo) {
      document.body.classList.add("no-drop");
      dragInfo.dragTarget.classList.add("cr-drag-target");
    },
    genDropzone() {
      if (this.dragComp !== "kanban") return;

      // 커서 class 지정

      this.$el
        .querySelectorAll(".cr-draggable-kanban")
        .forEach(el => el.classList.add("cr-drop-target"));
      this.$el
        .querySelectorAll(".cr-kanban-board-content")
        .forEach(el => el.classList.add("cr-drop-cursor"));
    },
    markDropzone(e, dragInfo) {
      if (this.dragComp !== "kanban") return;
      if (e.target.closest(".cr-kanban-ghost")) return;
      if (e.target.classList.contains("cr-kanban-board-content")) return;

      const { ghost } = dragInfo;
      const setInfo = (target, position, ghostParent) => {
        dragInfo["dropTarget"] = target;
        dragInfo["position"] = position;
        ghostParent[position](ghost);

        const crHelper = document.getElementById("crHelper");
        crHelper.classList.add("d-none");
        ghost.style.display = "block";
      };

      const target = e.target.closest(".cr-drop-target");
      if (!target) {
        const crHelper = document.getElementById("crHelper");
        crHelper.classList.remove("d-none");
        dragInfo["dropTarget"] = target;
        return (ghost.style.display = "none");
      }

      if (target.classList.contains("cr-bottom-section")) {
        return setInfo(target, "before", target);
      }

      const { height } = e.target.getBoundingClientRect();
      const twentyPercent = height * 0.2;
      const eightyPercent = height * 0.8;

      if (e.offsetY <= twentyPercent) {
        setInfo(target, "before", target);
      } else if (e.offsetY >= eightyPercent) {
        setInfo(target, "after", target);
      }
    },
    async mouseup(e, dragInfo) {
      const { dragTarget, dropTarget, position } = dragInfo;
      if (dropTarget) {
        const { id: taskId } = dragTarget.dataset;
        const { id: targetId, st: targetKey } = dropTarget.dataset;

        await this.moveTask({ taskId, targetId, targetKey, position });
      }

      dragInfo.ghost?.parentNode?.removeChild(dragInfo.ghost);
      const clsList = ["cr-drag-target", "cr-drop-target", "cr-drop-cursor"];
      this.$el
        .querySelectorAll(".cr-drag-target, .cr-drop-target, .cr-drop-cursor")
        .forEach(el => el.classList.remove(...clsList));
      document.body.classList.remove("no-drop");
    }
  }
};
</script>
