<template>
  <div
    class="cr-flow-task-detail-content-wrapper cr-scroll"
    :class="{ 'cr-scroll-move': isScroll, 'cr-collapsed': isDetailCollapsed }"
    @scroll="scroll"
  >
    <CollapsedContent v-if="isDetailCollapsed" />
    <ExpandedContent v-else />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-task-detail-content-wrapper::v-deep {
  overflow-y: scroll;
  padding: 12px 4px 30px 20px;
  &.cr-collapsed {
    padding: 12px 4px 30px 16px;
  }
  &.cr-scroll-move {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.25);
  }

  .cr-sub-title {
    font-weight: bold;
    font-size: 14px;
    padding: 16px 8px 12px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

<script>
import { mapState } from "vuex";
import CollapsedContent from "./collapsed";
import ExpandedContent from "./expanded";

export default {
  components: { CollapsedContent, ExpandedContent },
  data() {
    return { isScroll: false };
  },
  computed: {
    ...mapState("flowTasks", ["isDetailCollapsed"])
  },
  methods: {
    scroll(e) {
      this.isScroll = e.target.scrollTop > 10;
    }
  }
};
</script>
