<template>
  <div>
    <Item
      v-for="(item, idx) in subTasks"
      :key="idx"
      :item="item"
      :disabled="disabled"
      :showDetailBtn="showDetailBtn"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import Item from "./item";

export default {
  components: { Item },
  emits: ["change:Title", "click:member", "delete:subTask", "change:detailId"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDetailBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subTasks() {
      return this.task?.subTasks || [];
    }
  }
};
</script>
