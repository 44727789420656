<template>
  <v-tooltip :top="!isDetailCollapsed" :bottom="isDetailCollapsed">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        @click="SET_IS_DETAIL_COLLAPSED(!isDetailCollapsed)"
      >
        <v-icon size="20">
          {{ isDetailCollapsed ? "mdi-arrow-expand" : "mdi-arrow-collapse" }}
        </v-icon>
      </v-btn>
    </template>

    <span>{{ isDetailCollapsed ? "크게 보기" : "작게 보기" }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState("flowTasks", ["isDetailCollapsed"])
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_IS_DETAIL_COLLAPSED"])
  }
};
</script>
