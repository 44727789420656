<template>
  <div class="cr-project-manage-wrapper">
    <div class="cr-project-manage-list-wrapper">
      <SearchField />
      <List />
      <Loader />
    </div>

    <div class="cr-project-manage-detail-wrapper">
      <Detail />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-manage-wrapper::v-deep {
  display: flex;
  overflow-x: auto;
  height: 100%;

  .cr-project-manage-list-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70%;
    height: 100%;
    min-width: 800px;
  }

  .cr-project-manage-detail-wrapper {
    width: 30%;
    min-width: 400px;
    padding-left: 20px;
  }
}
</style>

<script>
import SearchField from "./searchField";
import List from "./list";
import Loader from "./loader";
import Detail from "./detail";

export default {
  components: { SearchField, List, Loader, Detail }
};
</script>
