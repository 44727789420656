<template>
  <div
    data-id="bottom"
    :data-st="status.value"
    class="cr-draggable-kanban cr-bottom-section"
  />
</template>

<style lang="scss" scoped>
.cr-bottom-section {
  display: flex;
  flex-grow: 1;
}
</style>

<script>
export default {
  props: {
    status: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
