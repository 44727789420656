<template>
  <div class="d-flex flex-grow-1" @click="$emit('click')">
    <v-tooltip top :disabled="!isIconMode">
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          block
          outlined
          v-on="on"
          :color="color"
          :icon="isIconMode"
          :class="{ 'cr-icon-btn': isIconMode }"
        >
          <IconMode v-if="isIconMode" v-bind="$props" />
          <TextMode v-else v-bind="$props" />
        </v-btn>
      </template>

      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.v-btn::v-deep {
  width: 100%;
  height: auto !important;
  min-height: 40px !important;
  padding: 0 12px !important;
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  .v-btn__content {
    max-width: 100%;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    width: fit-content;
    padding: 4px 0px 6px;
  }

  &.cr-icon-btn {
    padding: 0px !important;
    .v-btn__content {
      justify-content: center !important;
      padding: 4px 0px;
    }
  }
}
</style>

<script>
import IconMode from "./iconMode";
import TextMode from "./textMode";

export default {
  components: { IconMode, TextMode },
  props: {
    mode: {
      type: String,
      default: "text"
    },
    assignees: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isIconMode() {
      return this.mode === "icon";
    },
    existsAssignee() {
      return !!this.assignees.length;
    },
    color() {
      if (this.isIconMode && this.existsAssignee) return "primary";
      return "";
    },
    tooltip() {
      if (!this.existsAssignee) return "담당자 지정";
      return this.assignees.map(a => a.userName).join(", ");
    }
  }
};
</script>
