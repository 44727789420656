<template>
  <span v-if="existsAssignee">{{ icon }}</span>
  <v-icon v-else>{{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "text"
    },
    assignees: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    existsAssignee() {
      return !!this.assignees.length;
    },
    icon() {
      if (!this.existsAssignee) return "mdi-account";
      const [{ userName }] = this.assignees;
      return userName.split("")[0];
    }
  }
};
</script>
