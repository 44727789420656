<template>
  <v-btn
    small
    depressed
    outlined
    :color="color"
    :ripple="!isPending"
    class="px-2"
    :class="{ 'cr-pending': isPending }"
    height="22"
    @click.stop="click"
  >
    {{ title }}
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn.cr-pending {
  cursor: default;
  &.theme--light.v-btn:hover::before {
    opacity: 0 !important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    loginUser() {
      if (!this.project) return null;

      const { id: userId } = this.getUserInfo;
      return this.project?.members?.find(m => m.userId == userId);
    },
    authority() {
      return this.loginUser?.authority;
    },
    joinStatus() {
      return this.loginUser?.joinStatus;
    },
    isJoined() {
      return !!this.loginUser;
    },
    isPending() {
      return this.isJoined && this.joinStatus === "PENDING";
    },
    isInvited() {
      return this.isJoined && this.joinStatus === "INVITED";
    },
    title() {
      if (this.isInvited) return "초대 수락";
      if (this.isPending) return "승인 대기 중";
      if (this.isJoined) return "참여중 | 이동";
      return "참여하기";
    },
    color() {
      if (this.isPending) return "grey darken-1";
      return "primary";
    }
  },
  methods: {
    ...mapActions("flowRoute", ["routeTasks"]),
    ...mapActions("flowProjects", ["applyProject", "acceptProjectMember"]),
    click() {
      const { id: projectId } = this.project;

      if (this.isPending) return;
      if (this.isInvited) return this.acceptProjectMember(projectId);
      if (this.isJoined) return this.routeTasks({ projectId });
      return this.applyProject(projectId);
    }
  }
};
</script>
