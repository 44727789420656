<template>
  <div
    class="cr-display-text"
    :class="{ disabled }"
    @click="$emit('changeEditable', true)"
  >
    {{ content }}
  </div>
</template>

<style lang="scss" scoped>
.cr-display-text {
  display: flex;
  height: 138px;
  width: 100%;
  border: thin solid transparent;
  border-radius: 8px;
  padding: 13px 11px 0px 11px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  line-height: 28px;
  overflow: scroll;
  white-space: pre-line;
  border-color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
  &.disabled {
    cursor: default;
  }
}
</style>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    content() {
      return this.task?.content || "업무에 필요한 정보를 작성해주세요.";
    }
  }
};
</script>
