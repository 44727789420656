<template>
  <div class="cr-task-sub-wrapper">
    <List v-bind="$props" v-on="$listeners" />
    <AddInput v-if="!disabled" :parentTask="task" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-sub-wrapper {
  padding: 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.03);
  border: thin solid rgba(0, 0, 0, 0.04);
}
</style>

<script>
import List from "./list";
import AddInput from "./addInput";

export default {
  components: { List, AddInput },
  emits: [
    "add:subTask",
    "change:Title",
    "change:rangeDate",
    "click:member",
    "delete:subTask",
    "change:detailId"
  ],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDetailBtn: {
      type: Boolean,
      default: false
    }
  }
};
</script>
