<template>
  <Menu ref="menu" v-bind="$props">
    <template v-slot:activator="{ click }">
      <Activator :mode="mode" :assignees="assignees" @click="click" />
    </template>

    <template v-slot:menu-content>
      <Content :assignees="assignees" v-on="$listeners" />
    </template>
  </Menu>
</template>

<script>
import Menu from "@/flow/views/components/commons/menu";
import Activator from "./activator";
import Content from "./content";

export default {
  components: { Menu, Activator, Content },
  emits: ["click:member"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    assignees() {
      return this.task?.assignees || [];
    }
  },
  watch: {
    assignees() {
      this.$refs.menu?.updateDimensions?.();
    }
  }
};
</script>
