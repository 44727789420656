<template>
  <div class="cr-kanban-board">
    <Header v-bind="$props" :isScroll="isScroll" />
    <Content v-bind="$props" :isScroll.sync="isScroll" />
    <Loader v-if="loading" />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-board {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 250px;
  min-height: 300px;
  background: #fff;
  margin: 0px 8px 4px 8px;
  padding-bottom: 4px;
  border-radius: 12px 12px 0px 0px;
}
</style>

<script>
import { mapState } from "vuex";
import Header from "./header";
import Content from "./content";
import Loader from "./loader";

export default {
  components: { Header, Content, Loader },
  props: {
    status: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { isScroll: false };
  },
  computed: {
    ...mapState("flowTasks", ["loading"])
  }
};
</script>
