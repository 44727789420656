<template>
  <div
    data-id="top"
    data-type="group"
    :data-gi="group.id"
    class="cr-task-group-wrapper cr-draggable-list"
    :class="{ 'cr-default-group': isDefaultGroup }"
  >
    <v-icon v-if="showDragIcon" class="cr-draggable-icon">mdi-drag</v-icon>
    <div class="d-flex align-center">
      <CollapsedBtn v-bind="$props" />
      <Title :group="group" />
    </div>

    <AddTask v-if="showAddInput" :group="group" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-group-wrapper::v-deep {
  position: sticky;
  top: 49px;
  z-index: 2;
  padding: 4px 8px;
  background: #fff;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  &:not(.cr-drop-target):hover {
    &::before {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      content: "";
      background: rgba(0, 0, 0, 0.03);
    }

    .cr-task-add-input {
      background: rgba(0, 0, 0, 0.08);
      border-color: rgba(0, 0, 0, 0.12);
    }

    .cr-draggable-icon {
      display: flex;
      &:hover {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .cr-draggable-icon {
    display: none;
    cursor: grab;
    position: absolute;
    top: calc(50% - 12px);
    left: -12px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Title from "./title";
import AddTask from "./AddTask.vue";
import CollapsedBtn from "./CollapsedBtn.vue";

export default {
  components: { CollapsedBtn, Title, AddTask },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    ...mapGetters("flowRoute", ["filterId", "searchWord"]),
    isDefaultGroup() {
      return this.group.isDefault === 1;
    },
    showDragIcon() {
      return (
        !this.isDefaultGroup &&
        (this.filterId || "all") === "all" &&
        !this.searchWord
      );
    },
    showAddInput() {
      return !this.collapsed && this.hasEditPermission;
    }
  }
};
</script>
