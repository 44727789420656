<template>
  <div class="cr-sub-tasks-wrapper cr-draggable-list">
    <task v-for="subTask in subTasks" :key="subTask.id" :task="subTask" />
  </div>
</template>

<style lang="scss" scoped>
.cr-sub-tasks-wrapper::v-deep {
  position: relative;
  padding: 8px 0px 8px 8px !important;
  min-height: 48px;
  &::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    border-radius: 8px;
    background-color: var(--v-accent-base);
    opacity: 0.12;
  }

  .cr-item {
    padding-left: 6px !important;
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  components: {
    task: () => import("../task")
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("flowTasks", ["taskDataMap"]),
    subTasks() {
      const { subTasks } = this.task;
      return subTasks.map(id => this.taskDataMap[id]);
    }
  }
};
</script>
