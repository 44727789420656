<template>
  <span>{{ projectInfo }}</span>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    projectInfo() {
      const { members } = this.project;
      const { userName } = members.find(m => m.authority == "MASTER");

      if (members.length == 1) return userName;
      return `${userName} 외 ${members.length - 1}명`;
    }
  }
};
</script>
