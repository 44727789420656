<template>
  <v-btn icon outlined @click="$emit('delete:subTask', item.id)">
    <v-icon>mdi-trash-can-outline</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn::v-deep {
  height: auto !important;
  min-height: 40px !important;
  padding: 0 12px !important;
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.12);
  margin-top: -5px;
  margin-right: 4px;
  margin-left: 4px;
}
</style>

<script>
export default {
  emits: ["delete:subTask"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
