<template>
  <div class="cr-check-item-wrapper">
    <div class="d-flex align-center py-1">
      <Title v-bind="$props" v-on="$listeners" />

      <div class="d-flex pt-1">
        <DateBtn v-if="!disabled" :task="item" v-on="$listeners" />
        <AssigneeBtn v-if="!disabled" :task="item" v-on="$listeners" />
        <DeleteBtn v-if="!disabled" :item="item" v-on="$listeners" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-check-item-wrapper::v-deep {
  margin-bottom: 4px;
  padding: 0px 4px;
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .v-btn {
    border: none !important;
    &:hover {
      border: thin solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}
</style>

<script>
import Title from "./Title.vue";
import DateBtn from "../../commons/DateBtn.vue";
import AssigneeBtn from "../../commons/AssigneeBtn.vue";
import DeleteBtn from "./DeleteBtn.vue";

export default {
  components: { Title, DateBtn, AssigneeBtn, DeleteBtn },
  emits: ["change:Title", "click:member", "delete:subTask", "change:detailId"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDetailBtn: {
      type: Boolean,
      default: false
    }
  }
};
</script>
