<template>
  <div v-if="members.length" class="mb-2">
    <div class="caption">{{ title }}</div>
    <Member v-for="(member, idx) in members" :key="idx" :member="member" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Member from "./Member.vue";

export default {
  components: { Member },
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    title() {
      const { key } = this.$vnode;
      switch (key) {
        case "MASTER":
          return "소유자";
        case "ADMIN":
          return "운영자";
        case "EDIT":
          return "편집자";
        case "READ":
          return "게스트";
        default:
          return "참여 대기";
      }
    },
    members() {
      const { key } = this.$vnode;
      const { members } = this.selectedProject;

      if (!members) return [];
      return members.filter(m =>
        key === "PENDING"
          ? m.joinStatus !== "ACCEPTED"
          : m.joinStatus === "ACCEPTED" && m.authority === key
      );
    }
  }
};
</script>
