var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
    DRAG_COMP: 'list',
    genHelper: _vm.genHelper,
    dragstart: _vm.dragstart,
    genDropzone: _vm.genDropzone,
    markDropzone: _vm.markDropzone,
    mouseup: _vm.mouseup
  }),expression:"{\n    DRAG_COMP: 'list',\n    genHelper,\n    dragstart,\n    genDropzone,\n    markDropzone,\n    mouseup\n  }"}],staticClass:"cr-flow-list-dnd-wrapper"},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }