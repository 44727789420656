<template>
  <div class="cr-task-mockup">
    <v-skeleton-loader class="d-flex" type="button,text" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-mockup::v-deep {
  height: 48px;
  .v-skeleton-loader {
    .v-skeleton-loader__text,
    .v-skeleton-loader__button {
      width: 24px;
      height: 24px;
      margin-top: 12px;
      margin-left: 14px;
    }
  }
}
</style>

<script>
export default {};
</script>
