<template>
  <div class="cr-project-detail-wrapper">
    <Header v-if="showDetail" />
    <Content v-if="showDetail" />
    <JoinBtn v-if="showDetail" />
    <Empty v-if="!showDetail" />
  </div>
</template>

<style lang="scss" scoped>
.cr-project-detail-wrapper::v-deep {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  border: thin solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0px 0px 0px 16px;
}
</style>

<script>
import { mapState } from "vuex";
import Empty from "./empty";
import Header from "./header";
import Content from "./content";
import JoinBtn from "./joinBtn";

export default {
  components: { Empty, Header, Content, JoinBtn },
  computed: {
    ...mapState("flowProjects", ["loading", "selectedProjectId"]),
    showDetail() {
      return !!this.selectedProjectId && !this.loading;
    }
  }
};
</script>
