<template>
  <div class="d-flex justify-space-between">
    <div class="d-flex align-center">
      <AvatarMenu :avatar="avatar" disabled />
      <Title v-bind="$props" />
    </div>

    <div class="d-flex">
      <FavoriteBtn v-bind="$props" />
      <DotsBtn v-bind="$props" />
    </div>
  </div>
</template>

<script>
import AvatarMenu from "@/flow/views/components/commons/avatarMenu";
import Title from "./Title.vue";
import FavoriteBtn from "./FavoriteBtn.vue";
import DotsBtn from "./DotsBtn.vue";

export default {
  components: { AvatarMenu, Title, FavoriteBtn, DotsBtn },
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    avatar() {
      return { ...this.project, size: 45 };
    }
  }
};
</script>
