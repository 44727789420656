<template>
  <div id="scrollEl" class="cr-list">
    <div class="cr-infinity-wrapper">
      <Header />
      <GroupContainer v-for="group in _groups" :key="group.id" :group="group" />
      <Loader v-if="loading" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-list {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 0px 0px 60px 16px;
  .cr-infinity-wrapper {
    width: max-content;
    min-width: 100%;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import Header from "./header";
import Loader from "./loader";
import GroupContainer from "./groupContainer";

export default {
  components: { Header, Loader, GroupContainer },
  computed: {
    ...mapState("flowTasks", ["loading"]),
    ...mapGetters("flowTasks", ["groups"]),
    _groups() {
      return this.loading ? [] : this.groups;
    }
  }
};
</script>
