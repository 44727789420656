<template>
  <v-card-actions class="justify-end">
    <v-btn v-if="showCloseBtn" text outlined @click="CLOSE_DIALOG">
      {{ $t("common.취소") }}
    </v-btn>
    <v-btn
      v-if="showConfirmBtn"
      outlined
      color="accent"
      @click="SET_CONFIRMED(true)"
    >
      {{ $t("common.확인") }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowDialog", ["type"]),
    showCloseBtn() {
      switch (this.type) {
        case "manageMember":
          return false;
        default:
          return true;
      }
    },
    showConfirmBtn() {
      switch (this.type) {
        case "manageMember":
          return false;
        default:
          return true;
      }
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED", "CLOSE_DIALOG"])
  }
};
</script>
