<template>
  <div class="d-flex">
    <v-checkbox
      dense
      hide-details
      hide-spin-buttons
      :disabled="loading"
      class="ma-0 pt-0"
      label="참여중인 프로젝트 제외"
      v-model="_excludeMyProjects"
    />
    <v-checkbox
      dense
      hide-details
      hide-spin-buttons
      :disabled="loading"
      class="ma-0 pt-0 ml-3"
      label="비공개 프로젝트 제외"
      v-model="_excludePrivateProjects"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-input--checkbox::v-deep {
  label {
    top: 0px !important;
  }
}
</style>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowProjects", [
      "loading",
      "excludeMyProjects",
      "excludePrivateProjects"
    ]),
    _excludeMyProjects: {
      get() {
        return this.excludeMyProjects;
      },
      set(bool) {
        this.SET_EXCLUDE_MY_PROJECTS(bool);
      }
    },
    _excludePrivateProjects: {
      get() {
        return this.excludePrivateProjects;
      },
      set(bool) {
        this.SET_EXCLUDE_PRIVATE_PROJECTS(bool);
      }
    }
  },
  methods: {
    ...mapMutations("flowProjects", [
      "SET_EXCLUDE_MY_PROJECTS",
      "SET_EXCLUDE_PRIVATE_PROJECTS"
    ])
  }
};
</script>
