<template>
  <v-text-field
    outlined
    hide-details
    placeholder="하위 업무를 추가하세요."
    v-model="title"
    @keydown.enter="addSubTask"
  >
    <template v-slot:append>
      <DateBtn :task="task" @change:rangeDate="changeRangeDate" />
      <AssigneeBtn :task="task" @click:member="clickMember" />
      <AddBtn @addSubTask="addSubTask" />
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  margin-top: 12px;
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  fieldset {
    border: none !important;
  }
  .v-input__append-inner {
    margin-top: 14px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import DateBtn from "../commons/DateBtn.vue";
import AssigneeBtn from "../commons/AssigneeBtn.vue";
import AddBtn from "./AddBtn.vue";

export default {
  components: { DateBtn, AssigneeBtn, AddBtn },
  emits: ["add:subTask"],
  props: {
    parentTask: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { title: "", assignees: [], dateRange: [] };
  },
  computed: {
    task() {
      const [startDateTimeMillis, dueDateTimeMillis] = this.dateRange;
      return {
        assignees: this.assignees,
        startDateTimeMillis,
        dueDateTimeMillis
      };
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    addSubTask() {
      const { title } = this;
      if (isBlank(title)) {
        const message = "하위 업무 제목은 필수 값 입니다.";
        return this.openSnackbar({ message });
      }

      const { subTasks: st } = this.parentTask;
      const subTasks = [...(st || [])].sort((a, b) => a.key - b.key);
      const key = (subTasks.at(-1)?.key || 0) + 1;
      const newSubTasks = { key, title };
      newSubTasks["startDateTimeMillis"] = this.dateRange[0];
      newSubTasks["dueDateTimeMillis"] = this.dateRange[1];
      newSubTasks["assignees"] = this.assignees;

      this.$emit("add:subTask", newSubTasks);
      this.title = "";
      this.assignees = [];
      this.dateRange = [];

      setTimeout(() => {
        const el = this.$el.closest(".cr-scroll");
        if (el) el.scrollTop += 100;
      }, 0);
    },
    changeRangeDate({ rangeDate }) {
      this.dateRange = rangeDate;
    },
    clickMember({ member }) {
      const { userId } = member;
      let assignees = [...this.assignees, member];
      if (this.assignees.find(a => a.userId === userId)) {
        assignees = assignees.filter(m => m.userId !== userId);
      }

      this.assignees = assignees;
    }
  }
};
</script>
