<template>
  <div v-if="!isSubTask" class="cr-task-checklist-wrapper py-4 px-5">
    <h4 class="black--text mb-4">하위 업무</h4>
    <SubTask
      showDetailBtn
      :task="task"
      :disabled="!hasEditPermission"
      @add:subTask="_addSubTask"
      @change:Title="changeTitle"
      @change:rangeDate="changeRangeDate"
      @click:member="clickMember"
      @delete:subTask="deleteSubTask"
      @change:detailId="task => showTaskDetail(task)"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-checklist-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 8px;
  background-color: #fff;
}
</style>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import SubTask from "@/flow/views/components/commons/task/subTask";

export default {
  components: { SubTask },
  computed: {
    ...mapState("flowTasks", ["taskDataMap"]),
    ...mapGetters("flowTasks", ["detailTask", "hasEditPermission"]),
    task() {
      let { subTasks } = this.detailTask;
      subTasks = subTasks?.map(id => this.taskDataMap[id]) || [];
      return { ...this.detailTask, subTasks };
    },
    isSubTask() {
      return this.task.parentId > 0;
    }
  },
  methods: {
    ...mapActions("flowTasks", [
      "addSubTask",
      "updateTitle",
      "updateRangeDate",
      "updateAssignee",
      "deleteTasks",
      "showTaskDetail"
    ]),
    _addSubTask(task) {
      const { id: parentId, groupId } = this.task;
      this.addSubTask({ ...task, parentId, groupId });
    },
    changeTitle(task) {
      const { id: taskId } = task;
      this.updateTitle({ ...task, taskId });
    },
    changeRangeDate({ task, rangeDate }) {
      const { id: taskId } = task;
      const [startDateTimeMillis, dueDateTimeMillis] = rangeDate;
      this.updateRangeDate({
        ...task,
        taskId,
        startDateTimeMillis,
        dueDateTimeMillis
      });
    },
    clickMember({ task, member }) {
      const { userId } = member;
      const { id: taskId } = task;
      this.updateAssignee({ taskId, userId });
    },
    deleteSubTask(taskId) {
      const { id: parentId } = this.task;
      this.deleteTasks({ tasks: [{ parentId, taskId }] });
    }
  }
};
</script>
