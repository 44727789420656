<template>
  <v-btn-toggle v-model="_viewType" tile dense mandatory color="primary">
    <v-btn
      v-for="({ type, title }, idx) in viewTypes"
      :key="type"
      :disabled="loading"
    >
      <span class="cr-title" :class="{ 'font-weight-bold': _viewType == idx }">
        {{ title }}
      </span>
    </v-btn>
  </v-btn-toggle>
</template>

<style lang="scss" scoped>
.v-item-group.v-btn-toggle {
  .v-btn {
    height: 48px;
    min-width: 120px;
    border-top: none !important;
    background-color: #fff;
    &:first-child {
      border-left: none;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
    }

    .cr-title {
      font-size: 14px;
      &.font-weight-bold {
        font-size: 18px;
      }
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["loading"]),
    ...mapState("flowRoute", ["projectId", "actionObj"]),
    ...mapGetters("flowRoute", ["viewType", "viewTypes"]),
    _viewType: {
      get() {
        const viewType = this.viewType;
        const index = this.viewTypes.findIndex(v => v.type === viewType);
        return index === -1 ? 0 : index;
      },
      set(index) {
        const { projectId } = this;
        const { type: viewType } = this.viewTypes[index];
        const actionObj = { ...JSON.parse(this.actionObj), viewType };

        this.routeTasks({ projectId, actionObj });
      }
    }
  },
  methods: {
    ...mapActions("flowRoute", ["routeTasks"])
  }
};
</script>
