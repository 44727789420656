<template>
  <v-text-field
    outlined
    hide-details
    placeholder="항목을 추가하세요."
    v-model="title"
    @keydown.enter="add"
  >
    <template v-slot:append>
      <!-- <DueDateBtn /> -->
      <v-btn small depressed color="accent" class="ml-2" @click="add">
        추가
      </v-btn>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  margin-top: 12px;
  background: #fff;
  border: thin solid rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  fieldset {
    border: none !important;
  }
  .v-input__append-inner {
    margin-top: 14px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";
// import DueDateBtn from "./DueDateBtn.vue";

export default {
  // components: { DueDateBtn },
  emits: ["add:checklist"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { title: "" };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    add() {
      const { title } = this;
      if (isBlank(title)) {
        return this.openSnackbar({ message: "항목명은 필수 값 입니다." });
      }

      const checklist = [...(this.task?.checklist || [])];
      checklist.sort((a, b) => a.key - b.key);
      const key = (checklist.at(-1)?.key || 0) + 1;
      const newChecklist = { key, title, checked: 0 };
      this.$emit("add:checklist", newChecklist);
      this.title = "";
    }
  }
};
</script>
