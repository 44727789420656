<template>
  <div class="d-felx flex-column pb-4 mr-4 mt-3">
    <Invited v-if="isInvited" />
    <Reapply v-if="isPending" />
    <v-btn depressed block :class="cls" :ripple="!isPending" @click="click">
      {{ btnTitle }}
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-join-btn {
  height: 44px;
  max-height: 44px;
  &.cr-pending {
    cursor: default;
    background-color: grey !important;
    &.theme--light.v-btn:hover::before {
      opacity: 0 !important;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Reapply from "./Reapply.vue";
import Invited from "./Invited.vue";

export default {
  components: { Invited, Reapply },
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    ...mapGetters("auth", ["getUserInfo"]),
    loginUser() {
      const { id: userId } = this.getUserInfo;
      const { members } = this.selectedProject;
      return members?.find(m => m.userId == userId);
    },
    authority() {
      return this.loginUser?.authority;
    },
    joinStatus() {
      return this.loginUser?.joinStatus;
    },

    isJoined() {
      return !!this.loginUser;
    },
    isMaster() {
      return this.isJoined && this.authority === "MASTER";
    },
    isDeletable() {
      const { members } = this.selectedProject;
      return (
        this.isMaster &&
        !members?.filter(
          m => m.authority !== "MASTER" && m.joinStatus === "ACCEPTED"
        )?.length
      );
    },

    isPending() {
      return this.isJoined && this.joinStatus === "PENDING";
    },
    isInvited() {
      return this.isJoined && this.joinStatus === "INVITED";
    },

    btnTitle() {
      if (this.isDeletable) return "프로젝트 삭제";
      if (this.isMaster) return "프로젝트 위임";
      if (this.isPending) return "프로젝트 참여 승인 대기 중";
      if (this.isInvited) return "프로젝트 초대 수락";
      if (this.isJoined) return "프로젝트 나가기";
      return "프로젝트 참여";
    },
    cls() {
      return {
        "cr-join-btn": true,
        primary: !this.isDeletable,
        "cr-pending": this.isPending,
        "red white--text": this.isDeletable
      };
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapActions("flowProjects", [
      "applyProject",
      "leaveProject",
      "deleteProject",
      "acceptProjectMember"
    ]),
    click() {
      if (this.isPending) return;

      const { id: projectId } = this.selectedProject;
      if (this.isDeletable) {
        this.deleteProject(projectId);
      } else if (this.isMaster) {
        this.delegate();
      } else if (!this.isJoined) {
        this.applyProject(projectId);
      } else if (this.isInvited) {
        this.acceptProjectMember(projectId);
      } else if (this.isJoined) {
        this.leaveProject(projectId);
      }
    },
    delegate() {
      const headline = "프로젝트 위임";
      const type = "delegateProject";
      const params = { project: this.selectedProject };

      this.SHOW_DIALOG({ headline, type, params });
    }
  }
};
</script>
