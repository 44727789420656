<template>
  <div class="cr-flow-task-title-wrapper">
    <Title
      v-model="title"
      :loading="loading"
      :disabled="!hasEditPermission"
      @enter="enter"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-task-title-wrapper {
  display: flex;
  padding: 0px 16px;
  background-color: #fff;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import Title from "@/flow/views/components/commons/task/title";

export default {
  components: { Title },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    title: {
      get() {
        return this.task?.title || "";
      },
      set() {}
    }
  },
  methods: {
    ...mapActions("flowTasks", ["updateTitle"]),
    async enter({ e, changeEditable }) {
      const { id: taskId } = this.task;
      let { value: title = "" } = e.target;
      title = title?.trim();
      if (!isBlank(title) && title !== this.title) {
        this.loading = true;
        await this.updateTitle({ taskId, title });
      }

      setTimeout(() => {
        this.loading = false;
        changeEditable();
      }, 300);
    }
  }
};
</script>
