<template>
  <div style="display: contents;">
    <span v-if="!assignees.length" class="cr-not-exists">
      없음
    </span>

    <Chip
      v-for="assignee in assignees"
      :key="assignee.userId"
      :assignee="assignee"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-not-exists {
  color: rgba(0, 0, 0, 0.5);
}
</style>

<script>
import Chip from "./Chip.vue";

export default {
  components: { Chip },
  props: {
    mode: {
      type: String,
      default: "text"
    },
    assignees: {
      type: Array,
      default: () => []
    }
  }
};
</script>
