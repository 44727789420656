<template>
  <div style="display: contents;">
    <v-text-field
      v-if="editable"
      ref="textRef"
      dense
      hide-details
      outlined
      :value="item.title"
      @blur="esc"
      @keydown.esc.stop="esc"
      @keydown.enter="enter"
    />

    <div v-else class="cr-checkitem" @click="changeEditable">
      {{ item.title }}
      <v-btn v-if="showDetailBtn" icon small @click.stop="changeDetailId">
        <v-icon size="18">mdi-swap-horizontal</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-checkitem::v-deep {
  display: flex;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 11px;
  border: thin solid transparent;
  .v-btn {
    display: none !important;
  }
  &:hover {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
    .v-btn {
      display: flex !important;
    }
  }
}

.v-text-field::v-deep {
  background: #fff;
  border-radius: 8px !important;
  fieldset {
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
  input {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>

<script>
import { isBlank } from "@/commons/utils/validation";

export default {
  emits: ["change:Title", "change:detailId"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDetailBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { editable: false };
  },
  methods: {
    changeEditable() {
      if (this.disabled) {
        return this.$emit("change:detailId", this.item);
      }

      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    esc() {
      this.editable = false;
      this.$refs.textRef.reset();
    },
    enter(e) {
      const title = e.target.value.trim();
      if (isBlank(title)) return;

      this.$emit("change:Title", { ...this.item, title });
      this.esc();
    },
    changeDetailId() {
      this.$emit("change:detailId", this.item);
    }
  }
};
</script>
