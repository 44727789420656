<template>
  <v-text-field
    dense
    outlined
    autofocus
    hide-details
    class="mt-1"
    prepend-inner-icon="mdi-magnify"
    placeholder="멤버 이름으로 검색해주세요."
    v-model="_searchWord"
  >
    <template v-slot:append>
      <div style="width: 36px;">
        <ClearBtn v-if="_searchWord" @clear="_searchWord = ''" />
      </div>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  .v-input__slot {
    min-height: 36px !important;
    .v-input__prepend-inner {
      margin-top: 7px !important;
    }
    .v-input__append-inner {
      margin-top: 4px !important;
    }
    fieldset {
      border-radius: 8px;
    }
  }
}
</style>

<script>
import ClearBtn from "./ClearBtn.vue";

export default {
  components: { ClearBtn },
  props: {
    searchWord: {
      type: String,
      default: ""
    }
  },
  computed: {
    _searchWord: {
      get() {
        return this.searchWord;
      },
      set(searchWord) {
        this.$emit("update:searchWord", searchWord);
      }
    }
  }
};
</script>
