<template>
  <v-icon v-if="showDragIcon" class="cr-draggable-icon">
    mdi-drag
  </v-icon>
</template>

<style lang="scss" scoped>
.cr-draggable-icon {
  cursor: grab;
  display: none;
  position: absolute;
  left: -12px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"]),
    ...mapGetters("flowRoute", ["filterId", "searchWord"]),
    showDragIcon() {
      const { filterId = "all", searchWord } = this;
      return filterId === "all" && !searchWord && this.hasEditPermission;
    }
  }
};
</script>
