<template>
  <div
    :style="style"
    :class="{ 'pa-2': !fitContent }"
    class="cr-menu-content-wrapper"
  >
    <div
      v-if="!noArrow"
      class="menu-arrow"
      :class="{ top: !bottom, bottom }"
      :style="arrowStyle"
    />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.cr-menu-content-wrapper {
  position: absolute;
  top: -64px;
  left: 0px;
  background-color: #fff;

  border-radius: 8px;
  z-index: 999;
  min-width: 150px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);

  .menu-arrow.top {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #fff;
  }

  .menu-arrow.bottom {
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #fff;
  }

  .menu-arrow.right {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }

  .menu-arrow.top-left {
    position: absolute;
    bottom: -10px;
    right: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid #fff;
    border-right: 10px solid #fff;
  }

  .menu-arrow.top-right {
    position: absolute;
    bottom: -10px;
    left: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid #fff;
    border-left: 10px solid #fff;
  }

  .menu-arrow.bottom-left {
    position: absolute;
    top: -10px;
    right: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-right: 10px solid #fff;
  }

  .menu-arrow.bottom-right {
    position: absolute;
    top: -10px;
    left: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-left: 10px solid #fff;
  }
}
</style>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    targetRect: {
      type: DOMRect,
      default: () => ({})
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.updateDimensions();
    window.addEventListener("click", this.clickOutside, true);
    window.addEventListener("keydown", this.keydown, true);
  },
  destroyed() {
    window.removeEventListener("click", this.clickOutside, true);
    window.removeEventListener("keydown", this.keydown, true);
  },
  data() {
    return {
      arrowStyle: "",
      bottom: false,
      top: 0,
      left: 0
    };
  },
  computed: {
    style() {
      if (!this.fitContent) {
        return `transform: translate(${this.left}px, ${this.top}px);`;
      }

      const { width } = this.targetRect;
      return `transform: translate(${this.left}px, ${this.top}px); min-width:${width}px; max-width:${width}px`;
    }
  },
  watch: {
    targetRect({ top, left }) {
      if (!top || !left) return;

      this.updateDimensions();
    }
  },
  methods: {
    updateDimensions() {
      this.$nextTick(() => {
        const { width, height, top, left } = this.targetRect;
        const mainWrapElement = document.querySelector(".v-main__wrap");
        const { offsetLeft } = mainWrapElement;

        // 상수 정의
        const ARROW_HEIGHT = this.noArrow ? 0 : 10;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // 메뉴의 크기 계산
        const menuWidth = this.$el.offsetWidth;
        const menuHeight = this.$el.offsetHeight;

        // 상하 조절
        const totalHeight = menuHeight + ARROW_HEIGHT;
        let _top = top + height + ARROW_HEIGHT;
        let _bottom = false;

        // 메뉴가 화면 하단을 벗어나는지 확인
        if (_top + menuHeight > screenHeight) {
          _top = top - totalHeight;
          _bottom = true;
        }

        // 메뉴가 화면 상단을 벗어나지 않도록 조정
        this.top = Math.max(_top, 0);
        this.bottom = _bottom;

        // 좌우 조절
        let arrowStyle = "";
        let menuLeft = left - offsetLeft + (width - menuWidth) / 2;
        const menuRightEdge = menuLeft + menuWidth;
        const adjustedScreenWidth = screenWidth - offsetLeft;

        // 메뉴가 화면 우측을 벗어나는 경우
        if (menuRightEdge > adjustedScreenWidth) {
          const overflow = menuRightEdge - adjustedScreenWidth;
          menuLeft -= overflow;
          arrowStyle = `left: calc(50% + ${overflow}px)`;
        }

        // 메뉴가 화면 좌측을 벗어나지 않도록 조정
        if (offsetLeft <= 0 && menuLeft < 0) {
          arrowStyle = `left: calc(50% + ${menuLeft}px)`;
          menuLeft = 0;
        }

        this.left = menuLeft;
        this.arrowStyle = arrowStyle;
      });
    },
    clickOutside(e) {
      if (e.target.closest(".cr-menu-content-wrapper")) return;
      if (e.target.closest(".cr-menu-activator-wrapper.show")) return;

      this.$emit("close");
    },
    keydown(e) {
      if (e.keyCode !== 27) return;

      this.$emit("close");
    }
  }
};
</script>
