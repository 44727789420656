<template>
  <div class="grey--text-darken1 task-title" @click="showTaskDetail(task)">
    {{ task.title }}
  </div>
</template>

<style lang="scss" scoped>
.task-title {
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 0px 12px;
  margin-bottom: 4px;
  border: thin solid transparent;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    color: #333 !important;
    font-weight: bold;
    text-decoration: underline;
    border-color: rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("flowTasks", ["showTaskDetail"])
  }
};
</script>
