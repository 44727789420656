<template>
  <v-checkbox
    dense
    hide-details
    hide-spin-buttons
    color="grey"
    :indeterminate="indeterminate"
    :disabled="!hasEditPermission"
    v-model="checked"
  />
</template>

<style lang="scss" scoped></style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["LIST", "checkedTasks"]),
    ...mapGetters("flowTasks", [
      "LIST_tasks",
      "LIST_total",
      "hasEditPermission"
    ]),
    loadedCnt() {
      const { LIST_tasks: LT } = this;
      const keys = Object.keys(LT);
      return keys.reduce((cnt, key) => cnt + (LT[key]?.length || 0), 0);
    },
    allTasks() {
      const { LIST_tasks: LT } = this;
      const keys = Object.keys(LT);
      return keys.reduce((arr, key) => [...arr, ...(LT[key] || [])], []);
    },
    checked: {
      get() {
        return this.LIST_total && this.LIST_total === this.checkedTasks.length;
      },
      set(checked) {
        let checkedTasks = [];
        if (checked) {
          // 전체 체크를 하면 가지고있는 업무는 넣어주고 서버로부터 받아오지 않은 업무는 isEmpty true값으로 채워준다.
          let remainingCnt = this.LIST_total - this.loadedCnt;
          remainingCnt = remainingCnt < 0 ? 0 : remainingCnt;
          const emptyArr = new Array(remainingCnt).fill({ isEmpty: true });
          checkedTasks = [...this.allTasks, ...emptyArr];
        }

        this.SET_NEXT_PAGE_SELECT(checked);
        this.SET_CHECKED_TASKS(checkedTasks);
      }
    },
    indeterminate() {
      return (
        this.LIST_total > 0 &&
        this.checkedTasks.length > 0 &&
        this.checkedTasks.length !== this.LIST_total
      );
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEXT_PAGE_SELECT", "SET_CHECKED_TASKS"])
  }
};
</script>
