<template>
  <div class="cr-task-content-wrapper py-4 px-5">
    <h4 class="black--text mb-4">상세 내용</h4>
    <div class="cr-task-content">
      <Content
        :task="detailTask"
        :loading="loading"
        :disabled="!hasEditPermission"
        @enter="enter"
      />
      <span class="cr-description">Shift + Enter 시 줄바꿈</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-task-content-wrapper {
  border-radius: 8px;
  background-color: #fff;

  .cr-task-content {
    display: flex;
    flex-direction: column;
    align-items: end;

    .cr-description {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Content from "@/flow/views/components/commons/task/content";

export default {
  components: { Content },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("flowTasks", ["detailTask", "hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateContent"]),
    async enter({ e, toggleEditable }) {
      const { id: taskId } = this.detailTask;
      let { value: content = "" } = e.target;
      content = content?.trim();
      if (content !== this.content) {
        this.loading = true;
        await this.updateContent({ taskId, content });
      }

      setTimeout(() => {
        this.loading = false;
        toggleEditable();
      }, 300);
    }
  }
};
</script>
