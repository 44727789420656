<template>
  <v-card-text>
    <component :is="component" v-on="$listeners" />
  </v-card-text>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("flowDialog", ["type"]),
    component() {
      if (!this.type) return null;
      return () => import(`./${this.type}`);
    }
  }
};
</script>
