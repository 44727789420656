<template>
  <v-btn
    outlined
    height="40"
    class="cr-search-btn"
    color="primary darken-2"
    :disabled="loading"
    @click="toggleShowMyProjects"
  >
    <v-icon color="primary darken-2">mdi-magnify</v-icon>
    <span class="primary--text text--darken-2">프로젝트 검색</span>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn::v-deep {
  position: absolute;
  top: 0;
  right: 0px;
  transition: transform 0.2s ease, opacity 0.3s ease, visibility 0.3s ease;
}
</style>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("flowProjects", ["loading"])
  },
  methods: {
    ...mapActions("flowProjects", ["toggleShowMyProjects"])
  }
};
</script>
