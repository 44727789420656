<template>
  <div :class="{ disabled }">
    <CheckItem
      v-for="(item, idx) in checklist"
      :key="idx"
      :item="item"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="scss" scoped>
.disabled {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>

<script>
import CheckItem from "./item";

export default {
  components: { CheckItem },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checklist() {
      return this.task?.checklist || [];
    }
  }
};
</script>
