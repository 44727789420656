<template>
  <v-card-title
    v-if="headline"
    class="text-h6 font-weight-bold justify-space-between"
  >
    <span>{{ headline }}</span>
    <v-btn icon @click="CLOSE_DIALOG">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowDialog", ["headline"])
  },
  methods: {
    ...mapMutations("flowDialog", ["CLOSE_DIALOG"])
  }
};
</script>
