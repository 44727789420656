<template>
  <v-date-picker
    range
    no-title
    color="primary"
    :day-format="dayFormat"
    v-model="dateRange"
    @input="input"
  />
</template>

<style lang="scss" scoped>
.v-picker.v-card.v-picker--date::v-deep {
  .v-date-picker-header {
    padding-top: 16px;
  }
  > .v-picker__body .v-date-picker-table {
    height: 232px;

    table {
      thead tr th,
      tbody tr td {
        border-left: none;
        border-right: none;

        > button.v-btn.v-btn--rounded {
          border-radius: 0;
          width: 38px;
          &.primary {
            background-color: var(--v-primary-lighten5) !important;
            &.v-date-picker--last-in-range,
            &.v-date-picker--first-in-range {
              background-color: var(--v-primary-base) !important;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  emits: ["change"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    const { startDateTimeMillis: start, dueDateTimeMillis: due } = this.task;
    if (!start || !due) return;

    this.dateRange = [this.dateFormat(start), this.dateFormat(due)];
  },
  data() {
    return { dateRange: [] };
  },
  watch: {
    task({ startDateTimeMillis: start, dueDateTimeMillis: due }) {
      this.dateRange = [this.dateFormat(start), this.dateFormat(due)];
    }
  },
  methods: {
    dateFormat(date) {
      return getDateWithoutTime(date, "YYYY-MM-DD");
    },
    dayFormat(date) {
      return new Date(date).getDate();
    },
    // 날짜 눌렀을때 타는 함수
    input(dateRange) {
      if (dateRange.length !== 2) return;

      const [a, b] = dateRange.map(date => new Date(date).getTime());
      const rangeDate = a > b ? [b, a] : [a, b];
      this.$emit("change", rangeDate);
    }
  }
};
</script>
