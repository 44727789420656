<template>
  <v-text-field
    dense
    outlined
    hide-details
    :disabled="loading"
    class="mr-2 align-end"
    prepend-inner-icon="mdi-magnify"
    placeholder="검색어를 입력하세요."
    v-model="_searchWord"
    @keydown.enter="enter"
  />
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  height: 48px;
  max-width: 400px;
  .v-input__slot {
    fieldset {
      border-radius: 8px;
    }
  }
}
</style>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["loading"]),
    ...mapState("flowRoute", ["projectId", "actionObj"]),
    ...mapGetters("flowRoute", ["searchWord"]),
    _searchWord: {
      get() {
        return this.searchWord;
      },
      set() {}
    }
  },
  methods: {
    ...mapActions("flowRoute", ["routeTasks"]),
    enter(e) {
      const { projectId } = this;
      const { value: searchWord } = e.target;
      const actionObj = { ...JSON.parse(this.actionObj), searchWord };
      this.routeTasks({ projectId, actionObj });
    }
  }
};
</script>
