<template>
  <div
    :id="`task_${task.id}`"
    :data-type="'task'"
    :data-id="task.id"
    :data-pi="task.parentId"
    :data-gi="task.groupId"
    class="cr-draggable-list"
  >
    <div class="cr-item" :class="{ 'cr-selected': isSelected }">
      <DragIcon :task="task" />
      <Checkbox :task="task" />
      <component
        v-for="{ component, value, mode } in $props.components"
        :key="value"
        :is="component"
        :mode="mode"
        :task="task"
        :class="`px-3 py-2 task-${value}`"
      />
    </div>

    <SubTasks v-if="isSubTaskOpen" :task="task" />
  </div>
</template>

<style lang="scss" scoped>
.cr-item {
  display: flex;
  position: relative;
  align-items: center;
  height: 48px;
  padding: 4px 0px 4px 14px;
  &.cr-selected {
    background: rgba(0, 0, 0, 0.03);
  }
}

.cr-draggable-list:not(.cr-not-hover):not(.cr-drop-target)::v-deep {
  .cr-item:hover {
    .cr-draggable-icon {
      display: flex;
      &:hover {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &::before {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      content: "";
      background: rgba(0, 0, 0, 0.03);
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.05),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .task-title:hover:not(:has(.cr-open-sub-btn-wrapper:hover)) {
      cursor: pointer;
      .cr-text-deco {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: underline;
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import DragIcon from "./DragIcon.vue";
import Checkbox from "./Checkbox.vue";
import SubTasks from "./SubTasks.vue";

export default {
  components: { DragIcon, Checkbox, SubTasks },
  name: "task",
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Array,
      default: () => [
        {
          value: "number",
          component: () =>
            import("@/flow/views/components/tasks/attrs/viewer/taskNumber")
        },
        {
          value: "title",
          component: () => import("./title")
        },
        {
          value: "status",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/status")
        },
        {
          value: "priority",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/priority")
        },
        {
          mode: "icon",
          value: "assignees",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/assignees")
        },
        {
          value: "creator",
          component: () =>
            import("@/flow/views/components/tasks/attrs/viewer/creator")
        },
        {
          value: "startDate",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/startDate")
        },
        {
          value: "dueDate",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/dueDate")
        },
        {
          value: "progress",
          component: () =>
            import("@/flow/views/components/tasks/attrs/withStore/progress")
        }
      ]
    }
  },
  computed: {
    ...mapState("flowTasks", ["detailTaskId", "openSubTasks"]),
    isSelected() {
      return this.detailTaskId === this.task.id;
    },
    isSubTaskOpen() {
      const { id: taskId } = this.task;
      return this.openSubTasks.indexOf(taskId) > -1;
    }
  }
};
</script>
