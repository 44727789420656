<template>
  <DragAndDrop>
    <InfinityList />
    <div id="crListGhost" class="cr-drag-ghost" />
  </DragAndDrop>
</template>

<style lang="scss" scoped>
.cr-drag-ghost {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;

  &.cr-group {
    height: 53px !important;
    padding-top: 4px !important;
    padding-left: 44px !important;
  }
}

.cr-drag-ghost::v-deep,
.cr-list::v-deep {
  .task-number {
    width: 70px;
    min-width: 70px;
    text-align: center;
  }
  .task-title {
    flex-grow: 1;
    min-width: 360px;
    max-width: 525px;
  }
  .task-status {
    width: 120px;
    min-width: 120px;
  }
  .task-priority {
    width: 120px;
    min-width: 120px;
  }
  .task-assignees {
    width: 70px;
    min-width: 70px;
  }
  .task-creator {
    width: 120px;
    min-width: 120px;
  }
  .task-startDate,
  .task-dueDate {
    width: 160px;
    min-width: 160px;
  }
  .task-progress {
    width: 160px;
    min-width: 160px;
  }
}
</style>

<script>
import DragAndDrop from "./dragAndDrop";
import InfinityList from "./infinity";

export default {
  components: { DragAndDrop, InfinityList }
};
</script>
