<template>
  <div>
    <TaskMockup v-for="i in mockupCnt" :key="`${group.id}_${i}`" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TaskMockup from "./TaskMockup.vue";

export default {
  components: { TaskMockup },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (!this.mockupCnt) return;

    new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (!entry.isIntersecting) continue;
        if (!this.mockupCnt) return;

        const { id: groupId } = this.group;
        const lastTask = (this.LIST_tasks[groupId] || []).at(-1);
        this.getTasks({ sortOrder: lastTask?.listSortOrder, groupId });
      }
    }).observe(this.$el);
  },
  computed: {
    ...mapState("flowTasks", ["LIST"]),
    ...mapGetters("flowTasks", ["LIST_tasks"]),
    mockupCnt() {
      const { id: groupId } = this.group;
      const { total = 0 } = this.LIST[groupId] || {};
      const { length } = this.LIST_tasks[groupId] || [];

      return total - length < 0 ? 0 : total - length;
    }
  },
  methods: {
    ...mapActions("flowTasks", ["getTasks"])
  }
};
</script>
