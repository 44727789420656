<template>
  <div class="cr-flow-toolbar-wrapper">
    <LeftBtns />
    <SearchField />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-toolbar-wrapper {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  background: #fff;
  z-index: 2;
  padding-bottom: 8px;
}
</style>

<script>
import LeftBtns from "./leftBtns";
import SearchField from "./searchField";

export default {
  components: { LeftBtns, SearchField }
};
</script>
