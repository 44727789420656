<template>
  <Menu v-bind="$props">
    <template v-slot:activator="{ click }">
      <StatusBtn :status="status" :loading="loading" @click="click" />
    </template>

    <template v-slot:menu-content="{ close }">
      <div class="cr-flow-status-list">
        <div v-for="status in statuses" :key="status.value" class="mb-1">
          <StatusBtn :status="status" @click="changeStatus(status, close)" />
        </div>
      </div>
    </template>
  </Menu>
</template>

<style lang="scss" scoped>
.cr-flow-status-list {
  background: #fff;
  padding: 12px 8px 8px 8px;
}
</style>

<script>
import Menu from "@/flow/views/components/commons/menu";
import StatusBtn from "./StatusBtn.vue";

export default {
  components: { Menu, StatusBtn },
  emits: ["change:status"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statuses: [
        { value: "REQUESTED", title: "요청", color: "#FFCB00" },
        { value: "IN_PROGRESS", title: "진행중", color: "#FDAB3D" },
        { value: "FEEDBACK", title: "피드백", color: "#037F4C" },
        { value: "COMPLETED", title: "완료", color: "#00C875" },
        { value: "ON_HOLD", title: "보류", color: "#BB3354" }
      ]
    };
  },
  computed: {
    status() {
      const { status = "REQUESTED" } = this.task;
      return this.statuses.find(s => s.value === status);
    }
  },
  methods: {
    changeStatus(status, close) {
      this.$emit("change:status", status.value);
      close();
    }
  }
};
</script>
