<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on">
        <v-icon size="18">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="$emit('changeEditable')">그룹 명 변경</v-list-item>
      <v-list-item @click="deleteGroup(group.id)">그룹 삭제</v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("flowTasks", ["deleteGroup"])
  }
};
</script>
