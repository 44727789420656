<template>
  <div>
    <v-btn
      small
      outlined
      class="mr-1"
      color="primary"
      height="24"
      @click="move"
    >
      이동
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"])
  },
  methods: {
    ...mapActions("flowRoute", ["routeTasks"]),
    move() {
      const { id: projectId } = this.selectedProject;
      this.routeTasks({ projectId });
    }
  }
};
</script>
