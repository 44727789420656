<template>
  <v-text-field
    ref="text"
    dense
    outlined
    hide-details
    :disabled="disabled"
    class="cr-search-field"
    prepend-inner-icon="mdi-magnify"
    placeholder="프로젝트 이름으로 검색하세요."
    v-model="title"
    @keydown="keydown = true"
    @keydown.esc="$emit('update:expand', false)"
    v-click-outside="() => $emit('update:expand', false)"
  >
    <template v-slot:append>
      <ClearBtn v-if="title" />
      <ExpandBtn v-bind="$props" v-on="$listeners" />
    </template>
    <template v-slot:append-outer>
      <ToggleBtn :timeout="timeout" />
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.2s ease, opacity 0.3s ease, visibility 0.3s ease;
  .v-input__append-outer,
  .v-input__append-inner {
    margin-top: 6px !important;
  }
  fieldset {
    left: 2px;
  }
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ClearBtn from "./clearBtn";
import ToggleBtn from "./toggleBtn";
import ExpandBtn from "./expandBtn";

export default {
  components: { ClearBtn, ToggleBtn, ExpandBtn },
  props: {
    expand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { timeout: null, keydown: false, disabled: false };
  },
  computed: {
    ...mapState("flowProjects", [
      "loading",
      "searchWord",
      "showMyProjects",
      "excludeMyProjects",
      "excludePrivateProjects"
    ]),
    title: {
      get() {
        return this.searchWord;
      },
      set(searchWord) {
        // 2byte 문자버그로 인해 텍스트 입력 후 페이지 클릭시 두번탐 -> keydown이벤트로 감지
        if (!this.keydown || this.searchWord == searchWord) return;
        this.SET_SEARCH_WORD(searchWord);
      }
    }
  },
  watch: {
    showMyProjects(showMyProjects) {
      if (showMyProjects) {
        return this.$emit("update:expand", false);
      }

      setTimeout(() => {
        this.$refs.text.focus();
      }, 150);
    },
    excludeMyProjects() {
      if (this.showMyProjects) return;
      this.changeOptions();
    },
    excludePrivateProjects() {
      if (this.showMyProjects) return;
      this.changeOptions();
    },
    title() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      if (this.showMyProjects) return;
      this.timeout = setTimeout(async () => {
        this.keydown = false;
        await this.getAllProjects();
        this.timeout = null;
      }, 300);
    }
  },
  methods: {
    ...mapMutations("flowProjects", ["SET_SEARCH_WORD"]),
    ...mapActions("flowProjects", ["getAllProjects"]),
    async changeOptions() {
      this.disabled = true;
      await this.getAllProjects();
      this.disabled = false;
    }
  }
};
</script>
