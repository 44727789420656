<template>
  <div v-if="!!member" class="cr-invited-wrapper caption">
    <span>{{ `멤버권한 : ${authority}` }}</span>
    <span class="mx-2">|</span>
    <span>{{ `초대시간 : ${joinRequestTime}` }}</span>
  </div>
</template>

<style lang="scss" scoped>
.cr-invited-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>

<script>
import { mapGetters } from "vuex";
import { getFullDate } from "@/commons/utils/moment";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    ...mapGetters("auth", ["getUserInfo"]),
    member() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject;
      return members?.find(m => m.userId == id);
    },
    joinRequestTime() {
      return getFullDate(this.member?.joinRequestTimeMillis || 0);
    },
    authority() {
      switch (this.member.authority) {
        case "ADMIN":
          return "운영자";
        case "EDIT":
          return "편집자";
        default:
          return "게스트";
      }
    }
  }
};
</script>
