<template>
  <div class="cr-collapsed-wrapper">
    <StatusAndPriority />
    <Content class="mt-3" />
    <InfoContainer />
    <Checklist />
    <SubTasks />
  </div>
</template>

<style lang="scss" scoped>
.cr-collapsed-wrapper {
  display: flex;
  flex-direction: column;
  // width를 지정해주지 않으면 다이얼로그의 크기가 변할 때 메뉴 width가 제대로 측정이 안됨
  width: 463px;
}
</style>

<script>
import StatusAndPriority from "../commons/statusAndPriority";
import Content from "../commons/content";
import InfoContainer from "../commons/infoContainer";
import Checklist from "../commons/checklist";
import SubTasks from "../commons/subTasks";

export default {
  components: {
    StatusAndPriority,
    Content,
    InfoContainer,
    Checklist,
    SubTasks
  }
};
</script>
