<template>
  <div v-if="loading" class="cr-project-wrapper">
    <div class="d-flex flex-wrap">
      <div
        v-for="idx in 16"
        class="cr-project"
        :key="idx"
        :class="{ 'pr-1': idx % 2, 'pl-1': !(idx % 2) }"
      >
        <div class="cr-border">
          <div class="d-flex justify-space-between fill-height">
            <div class="d-flex flex-column flex-grow-1 justify-space-between">
              <div class="d-flex align-top justify-space-between">
                <v-skeleton-loader
                  class="cr-ld-avatar"
                  type="list-item-avatar-two-line"
                />
                <v-skeleton-loader class="cr-ld-switch" type="chip" />
              </div>

              <div class="d-flex justify-space-between px-4 pb-2 align-end">
                <v-skeleton-loader class="cr-ld-text" type="chip" />
                <v-skeleton-loader class="cr-ld-btn" type="button" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-wrapper::v-deep {
  overflow-y: scroll;
  position: absolute;
  top: 54px;
  left: 0px;
  right: -16px;
  bottom: 0px;
  background-color: #fff;

  .cr-project {
    display: flex;
    width: 50%;
    height: 100px;
    margin-bottom: 8px;

    .cr-border {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //   padding: 8px 16px;
      width: 100%;
      border: thin solid rgba(0, 0, 0, 0.08);

      .cr-ld-avatar {
        width: 80%;
        .v-skeleton-loader__avatar {
          border-radius: 16%;
          margin-right: 12px;
        }
        .v-skeleton-loader__list-item-two-line,
        .v-skeleton-loader__list-item-avatar-two-line {
          height: 62px;
          padding: 0px 12px;
        }
        .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar {
          height: 45px;
          width: 45px;
        }
      }
      .cr-ld-switch {
        margin-right: 18px;
        padding-top: 7px;
        .v-skeleton-loader__chip {
          width: 50px;
          height: 24px;
          margin-right: 0px;
          margin-top: 4px;
        }
      }
      .cr-ld-text {
        .v-skeleton-loader__chip {
          height: 24px;
        }
      }
      .cr-ld-btn {
        .v-skeleton-loader__button {
          width: 54px;
          height: 20px;
        }
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
/**
    avatar: avatar,
    button: button,
    card-heading: heading,
    chip: chip,
    heading: heading,
    list-item-avatar: avatar, text,
    list-item-avatar-two-line: avatar, sentences,
    sentences: text@2,
   */
export default {
  computed: {
    ...mapState("flowProjects", ["loading"])
  }
};
</script>
