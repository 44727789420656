<template>
  <v-btn
    dark
    block
    depressed
    :loading="loading"
    :color="status.color"
    @click="$emit('click')"
  >
    <div class="text-truncate" :title="status.title">
      <span>{{ status.title }}</span>
    </div>
  </v-btn>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
