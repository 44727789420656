<template>
  <div>
    <div class="cr-sub-title pb-2">
      {{ item.title }}
    </div>
    <div class="px-1" style="min-height: 40px;">
      <component
        noArrow
        fitContent
        :task="task"
        :is="$props.components[item.value]"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-select::v-deep {
  .v-input__control:hover::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Object,
      default: () => ({
        Group: () =>
          import("@/flow/views/components/tasks/attrs/withStore/group"),
        Assignees: () =>
          import("@/flow/views/components/tasks/attrs/withStore/assignees"),
        StartDate: () =>
          import("@/flow/views/components/tasks/attrs/withStore/startDate"),
        DueDate: () =>
          import("@/flow/views/components/tasks/attrs/withStore/dueDate"),
        Progress: () =>
          import("@/flow/views/components/tasks/attrs/withStore/progress")
      })
    }
  },
  computed: {
    ...mapGetters("flowTasks", { task: "detailTask" })
  }
};
</script>
