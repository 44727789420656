<template>
  <div class="cr-kanban">
    <DragAndDrop>
      <Board v-for="status in statuses" :key="status.value" :status="status" />
    </DragAndDrop>
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban {
  display: flex;
  width: 100%;
  height: calc(100% - 56px);
  padding: 12px 16px 0px 16px;
  overflow-x: scroll;

  .cr-kanban-board:first-child {
    margin-left: 4px;
  }
  .cr-kanban-board:last-child {
    margin-right: 4px;
  }
}
</style>

<script>
import DragAndDrop from "./dragAndDrop";
import Board from "./board";

export default {
  components: { DragAndDrop, Board },
  data() {
    return {
      statuses: [
        { value: "REQUESTED", title: "요청", color: "#FFCB00" },
        { value: "IN_PROGRESS", title: "진행중", color: "#FDAB3D" },
        { value: "FEEDBACK", title: "피드백", color: "#037F4C" },
        { value: "COMPLETED", title: "완료", color: "#00C875" },
        { value: "ON_HOLD", title: "보류", color: "#BB3354" }
      ]
    };
  }
};
</script>
