<template>
  <v-menu v-if="items.length" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense class="pa-0 py-1">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    loginUser() {
      if (!this.project) return null;

      const { id: userId } = this.getUserInfo;
      return this.project?.members?.find(m => m.userId == userId);
    },
    authority() {
      return this.loginUser?.authority;
    },
    joinStatus() {
      return this.loginUser?.joinStatus;
    },

    isJoined() {
      return !!this.loginUser;
    },
    isAdmin() {
      return this.isJoined && this.authority === "ADMIN";
    },
    isMaster() {
      return this.isJoined && this.authority === "MASTER";
    },
    isDeletable() {
      return (
        this.isMaster &&
        !this.project?.members?.filter(
          m => m.authority !== "MASTER" && m.joinStatus === "ACCEPTED"
        )?.length
      );
    },

    isPending() {
      return this.isJoined && this.joinStatus === "PENDING";
    },
    isInvited() {
      return this.isJoined && this.joinStatus === "INVITED";
    },
    items() {
      if (this.isPending || this.isInvited) {
        return [];
      }

      if (this.isAdmin) {
        return [
          { title: "프로젝트 멤버 관리", action: this.manageMembers },
          { title: "프로젝트 나가기", action: this._leaveProject }
        ];
      }

      if (this.isDeletable) {
        return [
          { title: "프로젝트 수정", action: this.modify },
          { title: "프로젝트 멤버 관리", action: this.manageMembers },
          { title: "프로젝트 삭제", action: this._deleteProject }
        ];
      }

      if (this.isMaster) {
        return [
          { title: "프로젝트 수정", action: this.modify },
          { title: "프로젝트 멤버 관리", action: this.manageMembers },
          { title: "프로젝트 위임하기", action: this.delegate }
        ];
      }

      if (this.isJoined) {
        return [{ title: "프로젝트 나가기", action: this._leaveProject }];
      }

      return [];
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapMutations("flowProjects", ["SET_MANAGED_PROJECT_ID"]),
    ...mapActions("flowProjects", ["deleteProject", "leaveProject"]),
    _deleteProject() {
      const { id: projectId } = this.project;
      this.deleteProject(projectId);
    },
    _leaveProject() {
      const { id: projectId } = this.project;
      this.leaveProject(projectId);
    },
    modify() {
      const headline = "프로젝트 수정";
      const type = "addProject";
      const params = { project: this.project };

      this.SHOW_DIALOG({ headline, type, params });
    },
    delegate() {
      const headline = "프로젝트 위임";
      const type = "delegateProject";
      const params = { project: this.project };

      this.SHOW_DIALOG({ headline, type, params });
    },
    manageMembers() {
      this.SET_MANAGED_PROJECT_ID(this.project.id);
      this.SHOW_DIALOG({
        headline: "프로젝트 멤버 관리",
        type: "manageMember"
      });
    }
  }
};
</script>
