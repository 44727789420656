<template>
  <div>
    <v-btn
      small
      outlined
      class="mr-1"
      color="primary"
      height="24"
      @click="modify"
    >
      수정
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"])
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    modify() {
      const headline = "프로젝트 수정";
      const type = "addProject";
      const params = { project: this.selectedProject };

      this.SHOW_DIALOG({ headline, type, params });
    }
  }
};
</script>
