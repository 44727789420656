<template>
  <v-hover v-slot="{ hover }">
    <div class="cr-check-item-wrapper">
      <div class="d-flex align-center py-1">
        <Checkbox :item="item" v-on="$listeners" />
        <Title :item="item" :editable.sync="editable" v-on="$listeners" />

        <v-btn v-if="!editable && hover" icon @click="deleteItem">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-check-item-wrapper {
  padding: 0px 10px;
  &:hover {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
  }
}
</style>

<script>
import Checkbox from "./Checkbox.vue";
import Title from "./Title.vue";

export default {
  components: { Checkbox, Title },
  emits: ["delete:checklist"],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { editable: false };
  },
  methods: {
    deleteItem() {
      this.$emit("delete:checklist", this.item);
    }
  }
};
</script>
