<template>
  <v-menu
    v-if="!showMyProjects"
    attach
    :close-on-click="false"
    :close-on-content-click="false"
    content-class="cr-search-options elevation-2"
    v-model="_expand"
  >
    <template v-slot:activator="{ on }">
      <Activator v-on="on" />
    </template>

    <MenuContent />
  </v-menu>
</template>

<style lang="scss" scoped>
.cr-search-options::v-deep {
  background: #fff;
  top: 41px !important;
  left: 2px !important;
  right: 0px;
  height: 60px !important;
  display: flex;
  align-items: center;
  padding: 0px 12px;
}
</style>

<script>
import { mapState } from "vuex";
import Activator from "./Activator.vue";
import MenuContent from "./MenuContent.vue";

export default {
  components: { MenuContent, Activator },
  props: {
    expand: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("flowProjects", ["showMyProjects"]),
    _expand: {
      get() {
        return this.expand;
      },
      set(expand) {
        this.$emit("update:expand", expand);
      }
    }
  }
};
</script>
