<template>
  <div
    data-id="bottom"
    :data-gi="group.id"
    class="cr-draggable-list cr-bottom-section"
  />
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
