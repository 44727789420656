<template>
  <div class="ml-4 cr-project-title-wrapper">
    <div class="text-body-1 font-weight-bold black--text text-truncate">
      {{ project.title }}
    </div>
    <div class="text-truncate">
      {{ project.description }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 50px);
  height: 48px;
}
</style>

<script>
export default {
  props: {
    idx: {
      type: Number,
      default: 0
    },
    project: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
