<template>
  <v-list-item @click="$emit('click:member', member)">
    <v-list-item-action class="mr-4">
      <v-checkbox :input-value="checked" />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{ member.userName }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    assignees: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    checked() {
      const { userId } = this.member;
      return !!this.assignees.find(m => m.userId === userId);
    }
  }
};
</script>
