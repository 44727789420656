<template>
  <div
    class="cr-display-text"
    :class="{ 'multi-row': rows > 1, disabled }"
    @click="$emit('change:editable', true)"
  >
    {{ title }}
  </div>
</template>

<style lang="scss" scoped>
.cr-display-text {
  display: flex;
  min-height: 56px;
  align-items: center;
  border: thin solid transparent;
  border-radius: 8px;
  padding: 12px 11px;
  cursor: pointer;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1;
  line-height: 26px;
  &:not(.disabled):hover {
    border-color: rgba(0, 0, 0, 0.04);
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.multi-row {
    line-height: 27px;
    padding: 13px 11px 10px;
  }
  &.disabled {
    cursor: default;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        const { height } = this.$el.getBoundingClientRect();
        this.$emit("update:rows", Math.floor((height - 23) / 26));
      }, 250);
    });
  }
};
</script>
